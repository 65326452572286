import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import FeaturedNewsCard from "./FeaturedNewsCard";
import inputFeaturedNews from '../../../inputs/inputFeaturedNews';
import './style.css';

const FeaturedNews = () => {
  const featuredNewsList = inputFeaturedNews;

  const featuredNewsCards = featuredNewsList.map((featuredNewsInfo, i) => (
  <Col key={i}>
    <FeaturedNewsCard featuredNewsInfo={featuredNewsInfo} />
  </Col>
  ))

  return (
    <Container className="featuredNews" fluid>
      <Row>
        <h1 className="featuredNewsMainHeader wrapper">Featured News and Articles</h1>
      </Row>
      <Row className="featuredArticles" xs={1} md={3}> 
          {featuredNewsCards}
      </Row>
    </Container>
  )
}

export default FeaturedNews;
