import React from "react";
import "./style.css";
import "./CanNavbar.css";
import { Container, Card } from "react-bootstrap";

const Header = props => {
  return (
    <Container fluid className="full">
      <Card style={{ width: "100%", border: "none" }}>
        <Card.Img className="headerImg" variant="top" src={props.img} alt="Chicago Skyline" />
        <Card.ImgOverlay className="image-overlay">
          <Card.Text className="headerTxt col-xs-3 col-sm-4 col-lg-4 col-xl-5 offset-md-1" id ="text-up">
            {props.text}
          </Card.Text>
        </Card.ImgOverlay>
        <Card.ImgOverlay id="tagline-overlay">
          <Card.Text className="col-xs-3 col-sm-9 col-lg-9 col-xl-9 offset-md-1" id="taglineCss">
            {props.tagline}
          </Card.Text>
        </Card.ImgOverlay>
      </Card>
    </Container>
  );
};

export default Header;
