import React from 'react'
import "./style.css";

const NewsStoryCard = ({newsStoryInfo}) => {
  return (
    <div className="newsStoryCard">
        <h2 className="newsStoryMainHeader textPrimary3">{newsStoryInfo.title}</h2>
        <p className="newsStorySource">{newsStoryInfo.source}</p>
        <p className="newsStoryDescription">{newsStoryInfo.description}
            {newsStoryInfo.anchorText ? <a href={newsStoryInfo.url} target="_blank" rel="noopener noreferrer">
            {newsStoryInfo.anchorText}</a> : ""}
        </p>
 </div>
  )
}

export default NewsStoryCard
