import React from "react";
import Header from "../shared/header/Header.jsx";
import cityDesktop from "../../assets/banner-desktop.jpg";
import cityMobile from "../../assets/banner-mobile.jpg";
import UpcomingEvents from "./upcomingEvents/UpcomingEvents.jsx";
import PastEvents from "./pastEvents/PastEvents.jsx";
import FeaturedNews from "./news/FeaturedNews";
import Archive from "./archive/Archive";
import NewsStory from "./news/NewsStory";
import useWindowDimensions from "../shared/functions/headerBannerFormat.jsx";

const NewsEvents = () => {
  const img = useWindowDimensions().width >= 768 ? cityDesktop : cityMobile;
  
  return (
   <div>
    <Header
      img={img}
      text="Connecting Across ChicagoLand:
      News And Events"
    />
    <UpcomingEvents />
    <PastEvents />
    <FeaturedNews />
    <NewsStory />
    <Archive />
   </div>
);}

export default NewsEvents;
