import React from "react";
import ButtonInvolved from "./ButtonInvolved.jsx";
import employerButton from "../../../assets/employerButton.png";
import seekerButton from "../../../assets/seekerButton.png";
import "./style.css";
import { Container, Row, Col } from "react-bootstrap";



const GetInvolved = () => (
  <Container fluid className="involvedBackground backgroundPrimary1">
    <Row>
      <Col>
        <p className="involvedText textPrimary3 allCaps">
          Learn how you can get involved
        </p>
      </Col>
    </Row>
    <Row className="justify-content-center">
      <div className="home-img-container">
        <ButtonInvolved name="Employer" img={employerButton} link="/employers" />
      </div>
      <div className="home-img-container">
        <ButtonInvolved
          name="Career seeker"
          img={seekerButton}
          link="/careerseekers"
        />
      </div>
    </Row>
  </Container>
);

export default GetInvolved;
