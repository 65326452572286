const cal = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december"
];

const mon = monAbbrev => {
  for (let i = 0; i < cal.length; i++) {
    if (cal[i].includes(monAbbrev.trim())) {
      return i;
    }
  }
};

const isBetween = (num, low, high) => {
  return num >= low && num <= high;
};

const isRecruiting = recStr => {
  recStr = recStr.toLowerCase().replace(/\./g, "");
  if (recStr === "ongoing") {
    return true;
  }
  const recList = recStr.split("-");
  const beg = mon(recList[0]);
  let end = mon(recList[1]);
  const currentMonth = new Date().getMonth();
  if (beg > end) {
    end += 12;
    return (
      isBetween(currentMonth, beg, end) ||
      isBetween(currentMonth + 12, beg, end)
    );
  }
  return isBetween(currentMonth, beg, end);
};

export default isRecruiting;
