import React from "react";
import ButtonSocial from "./ButtonSocial";
import linkedinButtonMobile from "../../assets/LinkedinMobile.png";
import linkedinLogo from "../../assets/LinkedinIcon.png";

import "./style.css";

const Footer = () => (
  <div className="footerBackground backgroundPrimary3 footerText">
    <ButtonSocial
      img={linkedinLogo}
      imgMobile={linkedinButtonMobile}
      link="https://www.linkedin.com/company/chicago-apprentice-network/"
      altText="LinkedIn Logo"
    />
    <p className="textWhite">
      &copy; Chicago Apprentice Network. Terms of Service, Etc.
    </p>
  </div>
);

export default Footer;
