// sorts articles by date, allows devs to add in whatever order
// articles from the same month will currently display in order they were added

const sortNews = (inputNews) => {

  const newsArray = inputNews.sort(function(a, b) {
    const month1 = a.date.substring(0,1) === '0' ? parseInt(a.date.substring(1,2)) : parseInt(a.date.substring(0,2));
    const month2 = b.date.substring(0,1) === '0' ? parseInt(b.date.substring(1,2)) : parseInt(b.date.substring(0,2));
    const date1 = new Date(a.date.substring(3), month1);
    const date2 = new Date(b.date.substring(3), month2);
    return (date1 < date2) ? 1 : -1
  });

  const sortedNewsArray = [];

  // pushing all the now-sorted articles into a 2d array, if the years don't match it makes a new subarray and pushes to that
  let sortIndex = 0;
  for(let i = 0; i < newsArray.length; i ++) {
    if(i === 0 || sortedNewsArray[sortIndex][0].date.substring(3) !== newsArray[i].date.substring(3)) {
      sortedNewsArray.push([newsArray[i]])
    } else {
      sortedNewsArray[sortIndex] = [...sortedNewsArray[sortIndex], newsArray[i]]
    }
    sortIndex = sortedNewsArray.length-1
  }

  return sortedNewsArray;
}

export default sortNews;
