import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./components/home/Home";
import Employers from "./components/employers/Employers";
import CareerSeekers from "./components/careerSeekers/CareerSeekers";
import NewsEvents from "./components/newsEvents/NewsEvents";
import NotFound from "./components/notFound/NotFound";
import Footer from "./components/footer/Footer";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import CanNavbar from "./components/shared/header/Navbar";

const Routes = () => {
  return (
    <div>
      <ScrollToTop />
      <CanNavbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/employers" component={Employers} />
        <Route exact path="/careerseekers" component={CareerSeekers} />
        <Route exact path="/newsevents" component={NewsEvents} />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </div>
  );
};

export default Routes;
