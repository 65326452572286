export default {
  'Chicago Apprentice Network Members': [
    {
      location: '/assets/membersAndPartners/Google_Logo_DJ_2-01.png',
      name: 'Google'
    },
    {
      location: '/assets/membersAndPartners/AAH_Vertical_4C.png',
      name: 'Advocate Aurora Health'
    },
    {
      location:
        '/assets/membersAndPartners/logos-nm-logo-stacked-rgb-purple-png.png',
      name: 'Northwestern Medicine'
    },
    {
      location: '/assets/membersAndPartners/all_line_hor_notag_rgb_pos.png',
      name: 'Allstate'
    },
    {
      location: '/assets/membersAndPartners/accenture_logo.png',
      name: 'Accenture'
    },
    {
      location: '/assets/membersAndPartners/zurich_logo.PNG',
      name: 'Zurich'
    },
    {
      location: '/assets/membersAndPartners/aon_logo.png',
      name: 'AON'
    },
    {
      location: '/assets/membersAndPartners/Catalyte_logo.png',
      name: 'Catalyte'
    },
    {
      location: '/assets/membersAndPartners/rush_logo(renamed).jpg',
      name: 'Rush University Medical Center'
    },
    {
      location: '/assets/membersAndPartners/UA_logo_stack_rgb_r.png',
      name: 'United Airlines'
    },
    {
      location: '/assets/membersAndPartners/mcdonalds_logo.png',
      name: "McDonald's"
    },
    {
      location: '/assets/membersAndPartners/walgreens-boots-alliance_logo.png',
      name: 'Walgreens Boots Allliance'
    },
    {
      location: '/assets/membersAndPartners/the-hartford_logo.png',
      name: 'The Hartford'
    },
    {
      location: '/assets/membersAndPartners/sdi_logo.png',
      name: 'SDI Presence LLC'
    },
    {
      location: '/assets/membersAndPartners/chase_logo.jpg',
      name: 'JP Morgan Chase and Co'
    },
    {
      location: '/assets/membersAndPartners/cps_logo_solid.svg',
      name: 'Chicago Public Schools'
    },
    {
      location: '/assets/membersAndPartners/freedman-seating_logo.png',
      name: 'Freedman Seating Company'
    },
    {
      location: '/assets/membersAndPartners/journeycare_logo.png',
      name: 'JourneyCare'
    },
    {
      location: '/assets/membersAndPartners/aar_logo.png',
      name: 'AAR'
    },
    {
      location: '/assets/membersAndPartners/sp_logo.PNG',
      name: 'SP+'
    },
    {
      location: '/assets/membersAndPartners/weber-shandwick_logo.png',
      name: 'Weber Shandwick'
    },
    {
      location: '/assets/membersAndPartners/collision-solution_logo.jpg',
      name: 'Collision Solution'
    },
    {
      location: '/assets/membersAndPartners/greentarget_logo.jpg',
      name: 'Greentarget'
    },
    {
      location: '/assets/membersAndPartners/jst_logo.png',
      name: 'JST'
    },
    {
      location: '/assets/membersAndPartners/siemens_logo.jpg',
      name: 'Siemens'
    },
    {
      location: '/assets/membersAndPartners/strategia_logo.png',
      name: 'Strategia'
    },
    {
      location: '/assets/membersAndPartners/fhl-bank-chicago_logo.png',
      name: 'FHL Bank Chicago'
    },
    {
      location: '/assets/membersAndPartners/AAC-logo-shield-n-words.png',
      name: 'ABN-AMRO Clearing'
    },
    {
      location: '/assets/membersAndPartners/Power_Logo.jpg',
      name: 'Power Construction'
    },
    {
      location: '/assets/membersAndPartners/LMT-Onsrud.jpg',
      name: 'LMT Onsrud'
    },
    {
      location: '/assets/membersAndPartners/JA-of-Chicago-Green.png',
      name: 'Junior Achievement Chicago'
    },
    {
      location: '/assets/membersAndPartners/Gateway_Color_logo.png',
      name: 'Gateway Foundation'
    },
    {
      location: '/assets/membersAndPartners/CABlogo.jpg',
      name: 'Certified Auto Body'
    },
    {
      location: '/assets/membersAndPartners/Allanson_Auto_Body_logo.jpg',
      name: 'Allanson Auto Body'
    },
    {
      location: '/assets/membersAndPartners/rmsa_logo.png',
      name: 'Risk Management Solutions of America, Inc'
    }
  ],
  'Education Partners': [
    {
      location: '/assets/membersAndPartners/college-of-lake-county_logo.png',
      name: 'College of Lake County'
    },
    {
      location: '/assets/membersAndPartners/general-assembly_logo.png',
      name: 'General Assembly'
    },
    {
      location: '/assets/membersAndPartners/harper-college_logo.png',
      name: 'Harper College'
    },
    {
      location: '/assets/membersAndPartners/arrupe-college_logo.png',
      name: 'Arrupe College'
    },
    {
      location: '/assets/membersAndPartners/city-college-of-chicago_logo.png',
      name: 'City Colleges of Chicago'
    },
    {
      location: '/assets/membersAndPartners/DPI-PrimaryLogo.jpg',
      name: 'Discovery Partners Institute'
    }
  ],
  'Non-Profit Partners': [
    {
      location: '/assets/membersAndPartners/yupro_logo.png',
      name: 'Year Up Professional Resources, PBC'
    },
    {
      location: '/assets/membersAndPartners/i.c.stars_logo.png',
      name: 'I. C. Stars'
    },
    {
      location: '/assets/membersAndPartners/year-up_logo.png',
      name: 'Year Up'
    },
    {
      location:
        '/assets/membersAndPartners/chicagoland-chamber-of-commerce_logo.png',
      name: 'Chicagoland Chamber of Commerce'
    },
    {
      location:
        '/assets/membersAndPartners/skills-for-chicagoland-future_logo.png',
      name: "Skills for Chicagoland's Future"
    },
    {
      location: '/assets/membersAndPartners/one-million-degrees_logo.png',
      name: 'One Million Degrees'
    },
    {
      location: '/assets/membersAndPartners/genesys-works_logo.png',
      name: 'Genesys Works'
    },
    {
      location: '/assets/membersAndPartners/code-platoon_logo.png',
      name: 'Code Platoon'
    },
    {
      location: '/assets/membersAndPartners/c4ita_logo.png',
      name: 'Center for IT Talent Acceleration'
    }
  ]
};
