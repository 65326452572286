/*
Example setup:
  {
    name: "The title of the Event",
    image: "Filename of the image being used, wrapped in quotes",
    imageDescription: "Brief description of image, to be used as image's alt text",
    description1: "Short summary of the event",
    url1: "Link, if available (probably to a video)",
    anchorText1: "text that will be put inside a anchor tag, will be displayed in description (optional)",
    description2: "text that is after anchorText. It is plain text (optional)"
  }

Place the images themselves in the assets/pastEventPhotos folder
*/

export default [
  {
    name: "July 2024: Celebrating 2,000 apprenticeships and 110+ employers!",
    image: "2024-07 CAN 2k Speakers.jpg",
    imageDescription: "CAN 2K Speakers",
    description1: "What a celebration we had at Accenture Tower to celebrate the ",
    url1: "https://chicagoapprenticenetwork.org/#/",
    anchorText1: "Chicago Apprenticeship Network",
    description2: " hitting a milestone of creating 2,000 Apprenticeships in the Chicagoland area, "
    +
    "as well as the model being taken across the country creating almost 5,000 Apprenticeships in 10 Apprentice Networks!"
    +
    " It was an honor to hear from leaders & apprentices at Aon, Accenture & Zurich, as well as ",
    url2: "https://www.linkedin.com/in/brent-parton-a1218412/",
    anchorText2: "Brent Parton Principal at the U.S. Department of Labor",
    description3: ". If you weren’t at the event and would like to see the excitement, please watch the ",
    url3: "https://youtu.be/6ViB-SbNQsU",
    anchorText3: "replay."
  },
  {
    name: "February 2024 Event",
    image: "2024-02-12_CAN_event,_Dr._Chrissman_CEO_2.jpg",
    imageDescription: "February 2024 Event",
    description1: "In February 2024, we concluded our 16th ",
    url1: "https://chicagoapprenticenetwork.org/#/",
    anchorText1: "Chicago Apprenticeship Network",
    description2: " Event pulling together a record 325 employers, apprentices / graduates, educational institutions, non-profits and civic leaders! Special thanks to the whole ",
    url2: "https://www.nm.org/",
    anchorText2: "Northwestern Medicine",
    description3: " team for hosting. It was an honor to hear from the ",
    url3: "https://www.linkedin.com/in/howard-chrisman-md-5363109/",
    anchorText3: "Northwestern Medicine CEO",
    description4: " & ",
    url4: "https://www.linkedin.com/in/doug-king-5546294/",
    anchorText4: "CIO",
    description5: ", the",
    url5: "https://www.linkedin.com/in/clarisol-duque-80710a28/",
    anchorText5: "State Director at Office of U.S. Senator Dick Durbin",
    description6: ", the ",
    url6: "https://www.ccc.edu/menu/pages/chancellor.aspx",
    anchorText6: "Chancellor of City Colleges",
    description7: " and most importantly…Northwestern Apprentice Graduates! Keep an eye out for information on our next event in July 2024!"
  },
  {
    name: "November 2023 Event",
    image: "fall2023.png",
    imageDescription: "Chicago Apprenticeship Network Event",
    description1: "We concluded our 15th Chicago Apprenticeship Network Event on November 15 to celebrate ",
    url1: "https://www.apprenticeship.gov/",
    anchorText1: "National Apprentice Week",
    description2: " at ",
    url2: "https://www.united.com/en/us/fly/company/brand/good-leads-the-way.html",
    anchorText2: "United Airlines",
    description3:
      "’ corporate headquarters in downtown Chicago. It was a memorable evening, with a star-studded lineup of speakers and hundreds of apprentices, alumni and executives from dozens of Chicago’s top employers, colleges and nonprofits. The room was full and electric! It was an honor to hear from the ",
    url3: "https://www.dol.gov/agencies/osec",
    anchorText3: "Acting U.S. Secretary of Labor Julie Su",
    description4: " as well as leaders from ",
    url4: "https://careers.united.com/us/en/calibrate",
    anchorText4: "United Airlines",
    description5: ", the",
    url5: "https://www.businessroundtable.org/",
    anchorText5: "Business Roundtable",
    description6: ", ",
    url6: "https://p33chicago.com/",
    anchorText6: "P33",
    description7: ", ",
    url7: "https://www.icstars.org/",
    anchorText7: "i.c.stars",
    description8: " and ",
    url8: "https://teamster.org/",
    anchorText8: "International Brotherhood of Teamsters",
    description9: ". Keep an eye out for information about our next event that Northwestern Medicine will host in February 2024!"
  },
  {
    name: "May 2023 Event",
    image: "dpi.png",
    imageDescription: "Discovery Partners Institute logo",
    description1: "Another fabulous event in the books thanks to our host, ",
    url1: "https://dpi.uillinois.edu/",
    anchorText1: "the Discovery Partners Institute (DPI)",
    description2:
      ". Former U.S. Secretary of Education Arne Duncan and Pritzker Tech Talent Director Omowale Casselle addressed more than 200 apprentices from Aon, Accenture, Zurich North America and other employers, along with educators and nonprofits, during the Chicago Apprentice Network’s spring 2023 networking event, held at the Pritzker Tech Talent Labs in Chicago. Both leaders praised the network’s achievements, highlighted the greater diversity that apprenticeships bring to the workforce, and touted the value of apprenticeships for expanding pathways to career success."
  },
  {
    name: "Fall 2022 Event",
    image: "2022-11-14_Dr._Jill_Biden_on_stage_with_the_leaders.png",
    imageDescription: "Dr. Jill Biden on stage with the leaders",
    description1:
      "First Lady Jill Biden kicked off 2022 National Apprenticeship Week with " +
      "the Chicago Apprentice Network and Business Roundtable at Aon Center in Chicago. " +
      "The first lady, U.S. Secretary of Commerce Gina Raimondo, U.S. Secretary of Labor Marty Walsh" +
      ", and U.S. Secretary of Education Miguel Cardona met with CEOs and apprentices," +
      " and spoke about ways to continue scaling the apprenticeship model among employers," +
      " giving even more people access to great careers with paid training to be successful. ",
    url1: "https://www.youtube.com/watch?v=wYL14hMu-L0",
    anchorText1: "See a recording",
    description2: " of the event."
  },

  // C:\Users\awadud\Code\reactjs-application\can-website\src\assets\2022-11-14_Dr._Jill_Biden_on_stage_with_the_leaders.png

  {
    name: "Summer 2022 Event",
    image: "july-2022-roundtable.png",
    imageDescription: "Business Roundtable with CEOs Scott Kirby and Greg Case",
    description1: "Harold Washington College welcomed a panel discussion of the Business Roundtable, " + "featuring United Airlines CEO Scott Kirby and AON CEO Greg Case, " + 'discussing the new "Apprenticeship Accelerator" Corporate Initiative. ',
    url1: "https://vimeo.com/731508026",
    anchorText1: "Watch a recording",
    description2: " of the event."
  },
  {
    name: "Fall 2021 Event",
    image: "fall-2021-speakers.jpg",
    imageDescription: "Chicago Apprentice Network Quarterly Event Speakers",
    description1:
      "Our National Apprenticeship Week celebration introduced Chicagoland employers to OneTen (https://oneten.org), " +
      "which is working to close the opportunity gap for Black talent. " +
      "Aon and Allstate are the Chicago leads and Accenture is the NYC lead for OneTen. " +
      "Zurich North America announced its apprenticeship program is expanding to nine cities in 2022. " +
      "The Chicago Apprentice Network now numbers over 75 employers offering over 1, 200 apprenticeships, " +
      "and the network model continues to expand to other cities! ",
    url1: "https://www.youtube.com/watch?v=ajwuQzKcE74",
    anchorText1: "Watch a recording",
    description2: " of the event."
  },
  {
    name: "Spring 2021: Virtual",
    image: "spring-2021-virtual.jpg",
    imageDescription: "Chicago Apprentice Network Quarterly Event",
    description1:
      "U.S. Sen. Dick Durbin, Illinois Speaker of the House Chris Welch and Aon CEO Greg Case joined a celebration of a major milestone: Over 1,000 apprentice opportunities have been created by businesses in the Chicago Apprentice Network! " +
      "Apprentices from the founding companies Accenture, Aon and Zurich North America shared their journeys, and leaders from those companies shared lessons learned on the way to expanding the network to more than 50 employers. Juan Salgado, City Colleges " +
      "of Chicago Chancellor, and Jack Lavin, CEO/President of the Chicagoland Chamber of Commerce, offered congratulations to all. ",
    url1: "https://aon.imagencloud.com/record/~a48a896dd4",
    anchorText1: "Watch a recording",
    description2: " of the event."
  },
  // {
  //   name: "Fall 2020: AbbVie",
  //   image: "AbbVieLogo_Preferred_DarkBlue_on_white.jpg",
  //   imageDescription: "AbbVie logo",
  //   description1: "COVID-19 continued to shift our CAN events into a virtual format, but that didn"t stop us from pulling together an amazing event to celebrate National Apprentice Week and the launch of AbbVies apprentice program with the College of Lake County. " +
  //   "The Chicago Apprentice Network was also proud to publicly launch the CAN website that was highlighted by some of the apprentices that designed, built and launched the website." +
  //   "\n \nWe capped off this amazing event with a virtual networking session to allow employers that are interested in learning more about apprenticeships to talk directly to employers with established programs and our apprentices."
  // },
  {
    name: "Fall 2020: Virtual",
    image: "Rep_Krishnamoorthi_0.jpg",
    imageDescription: "Chicago Apprentice Network Q4 Event",
    description1:
      "More than 300 attendees joined virtually as Aon CEO Greg Case shared exciting news about the expansion of apprenticeships into multiple cities. Congressman Raja Krishnamoorthi, Accenture Senior Managing Director Jim Coleman, College of Lake County President Lori Suddick and SHRM CEO Johnny Taylor offered perspectives on the impact of apprenticeships in Chicagoland and beyond."
  },
  {
    name: "Spring 2020: Catalyte",
    image: "Catalyte Event 4.1.19.jpg",
    imageDescription: "Catalyte Spring 2020 virtual event",
    description1:
      "COVID-19 shifted CAN’s spring event to a virtual format, hosted by CAN member company Catalyte. Even in the midst of a pandemic, over 150 participants signed up to hear Catalyte apprentices and apprentice alumni share their experiences building highly marketable technology skills through apprenticeship."
  },
  {
    name: "Fall 2019: JourneyCare",
    image: "journeycare.png",
    imageDescription: "JourneyCare logo",
    description1:
      "At JourneyCare in Glenview, CEO Kimberly Hobson hosted a diverse group of leaders and apprentices from CAN companies, colleges, non-profits and the public sector for a round of speed networking and a keynote address from College of Lake County President Lori Suddick."
  },
  {
    name: "Summer 2019: JPMorgan Chase",
    image: "CAN events ApprenticeGroup_MayorLoriLightfoot03_CANevent_07-31-19_96dpi_2363.jpg",
    imageDescription: "Mayor Lightfoot with apprentices",
    description1:
      "Chicago Mayor Lori Lightfoot shared her excitement for apprenticeship during a CAN gathering at JPMorgan Chase downtown, drawing more than 225 attendees from over 16 companies as well as two dozen apprentices and alumni, in addition to representatives from nonprofits and education providers."
  },
  {
    name: "Spring 2019: Rush University Medical Center  ",
    image: "RUSH_logo.jpg",
    imageDescription: "Rush logo",
    description1:
      "Employers, apprentices, apprentice alumni, educational providers and public sector representatives convened to highlight CAN goals for 2019 and initiatives such as Chicago HEAL that are focused on increasing workforce development opportunities for communities in Chicago."
  },
  {
    name: "Winter 2019: Aon",
    image: "Winter 2019 (3).jpg",
    imageDescription: "Winter 2019 AON event",
    description1:
      "CAN announced a goal to create 1,000 apprenticeship opportunities by the end of 2020 as former U.S. Secretary of Commerce Penny Pritzker highlighted a $3.2 million Apprenticeship 2020 fund to boost apprenticeship in Chicago. More than 200 people, including media, attended at Aon in Chicago."
  },
  {
    name: "Fall 2018: McDonald’s",
    image: "mcdonalds.jpg",
    imageDescription: "McDonalds logo",
    description1: "McDonald’s new global headquarters in the West Loop of Chicago welcomed more than 200 members and friends of CAN to a networking event and panel discussion moderated by a Wall Street Journal journalist."
  },
  {
    name: "Summer 2018: Zurich North America",
    image: "CAN events Zurich.jpg",
    imageDescription: "Zurich CAN event",
    description1:
      "CAN member companies and apprentices convened at Zurich North America’s landmark LEED Platinum® certified headquarters along Interstate 90 in Schaumburg for mingling and a discussion on expanding apprenticeship opportunities in Chicagoland’s financial services sector."
  },
  {
    name: "Spring 2018: Accenture",
    image: "CAN events Accenture first quarterly event.jpg",
    imageDescription: "Accenture CAN event",
    description1:
      "The first quarterly CAN event attracted 50+ apprentices from Aon, Accenture and Zurich; 50+ representatives of 21+ companies with either an established apprentice program or interest in developing one; and 40+ representatives of educational institutions, non-profits and the public sector."
  }
];
