import React from "react";
import "./style.css";
import Box from "./box/Box";
import Mission from "./mission/Mission";
import { Row, Container, Col } from "react-bootstrap";

const OurMission = props => {
  return (
    <Container fluid className="wrapper missionBottomMargin top-margin">
      <Row>
        <Col md={6}>
          <Mission />
        </Col>
        <Col
          md={6}
          className="d-flex flex-column justify-content-center picBox"
        >
          <Box />
        </Col>
      </Row>
      <Row className="whatIsAppship justify-content-center" md={6}>
        <Col md={{ span: 8 }}>
          <h3>What are apprenticeships?</h3>
          Apprenticeships are “earn-and-learn” programs that combine formal
          learning (e.g. in-person, virtual or computer-based training) with
          paid, on-the-job training experiences. Businesses design
          apprenticeships in a number of ways, but the common thread is that
          they are mutually beneficial. Apprenticeships provide individuals with
          high-quality career pathways while helping employers develop their
          workforce for the needs of tomorrow. Apprenticeship is a win-win
          approach for a bright future of work.
        </Col>
      </Row>
      <Row className="justify-content-center" md={1}>
        <Col className="bullets" md={"auto"}>
          • Paid job
        </Col>
        <Col className="bullets" md={"auto"}>
          • On-the-job learning
        </Col>
        <Col className="bullets" md={"auto"}>
          • Classroom learning
        </Col>
        <Col className="bullets" md={"auto"}>
          • Mentorship
        </Col>
        <Col className="bullets" md={"auto"}>
          • Credentials
        </Col>
      </Row>
    </Container>
  );
};

export default OurMission;
