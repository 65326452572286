import React from 'react';
import YearArchive from './YearArchive';
import inputNews from '../../../inputs/inputNews';
import sortNews from './sortNews';
import './style.css'

const Archive = () => {

  const sortedNewsArray = sortNews(inputNews);

  const sortedYearArchives = sortedNewsArray.map((year, i) => (
    <YearArchive key={i} year={year[0].date.substring(3)} articleArray={year} />
  ))

  return(
    <div className="yearlyArchiveContainer">
      {sortedYearArchives}
    </div>
  )
}

export default Archive;
