export default {
  CommunityText:
    "Many local educational institutions and non-profits have joined with companies to offer career readiness and apprenticeship pipeline programs. Some opportunities are offered through a community college or other education provider. Other times you apply directly to a business.",

  CommunityColleges: [
    {
      logo: "/assets/membersAndPartners/city-college-of-chicago-alt_logo.png",
      desc:
        "The City Colleges apprenticeship program takes you from student to employee, opening the door into several of Chicago’s leading businesses. You’ll get an inside look at what life is really like in an industry or at a company and earn a living wage all before you finish college.",
      learnMoreURL: "https://apprenticeship.ccc.edu/studentgateway-2/",
    },
    {
      logo: "/assets/membersAndPartners/college-of-lake-county_logo.png",
      desc:
        "In fall 2019, the college launched a successful apprenticeship program where students can learn and earn. Career programs for apprenticeships help connect local employers with a tailored, high-quality talent pipeline.",
      learnMoreURL: "https://www.clcillinois.edu/student-services/additional-services/cjpcenter/launch/apprenticeships",
    },
    {
      logo: "/assets/membersAndPartners/harper-college_logo.png",
      desc:
        "A registered apprenticeship program sponsor, Harper College in Palatine provides apprenticeship-related instruction and program oversight for more than 55 employers, including Zurich North America, in 10 occupations so far.",
      learnMoreURL: "https://www.harpercollege.edu/index.php"
    },
    {
      logo:
        "/assets/membersAndPartners/general-assembly_logo(long).png",
      desc:
        "A leading source for training, staffing and career transitions, General Assembly fosters a flourishing community of professionals pursuing careers they love. GA confronts the global skills gap through award-winning instruction and innovative opportunities with top employers.",
      learnMoreURL: "https://generalassemb.ly/"
    },
    {
      logo:
        "/assets/membersAndPartners/arrupe-college_logo.png",
      desc:
        "Offering an associate degree program in the Jesuit tradition of rigorous education, Arrupe College is a two-year college of Loyola University Chicago that helps open opportunities to a diverse population of students, many of whom are the first in their families to pursue higher education.",
      learnMoreURL: "https://www.luc.edu/arrupe/"
    }
  ],

  nonProfits: [
    {
      logo: "/assets/membersAndPartners/i.c.stars_logo.png",
      desc:
        "This organization is dedicated to preparing underserved young adults for technologycareers and community leadership. i.c.stars has been proud to partner with Accenture for more than a decade, including as a talent source for its tech apprentices.",
        learnMoreURL: "https://www.icstars.org/program/",
    },
    {
      logo: "/assets/membersAndPartners/yupro_logo.png",
      desc:
        "This nation leading Opportunity Talent Placement Firm connects forward-thinking companies with diverse talent that drives long-term business success.",
      learnMoreURL: "http://yupro.com/"
    },
    {
      logo: "assets/membersAndPartners/code-platoon_logo.png",
      desc:
        "Helping with the transition into the civilian workforce, this organization transforms veterans and military spouses into professional software developers through an immersive, hands-on, educational process and paid internship program.",
      learnMoreURL: "https://www.codeplatoon.org/media-kit/"
    }
  ]
};
