import React from "react";
import CommunityColleges from "./CommunityColleges";
import NonProfits from "./NonProfits";
import './style.css';
import LineSeparator from "./lineSeparator";

const ExplorePrograms = (props) => {
  return (
    <>
      <CommunityColleges />
      <NonProfits />
      <LineSeparator/>
    </>
  );
};

export default ExplorePrograms;
