import React from "react";
import "./style.css";
import { Carousel, Row, Col } from "react-bootstrap";
import prevArrow from "../../../../assets/prevArrowRed.png";
import nextArrow from "../../../../assets/nextArrowRed.png";

class CanMembersCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logosArr:  [...this.props.logos] || []
        };

        this.slidesGenerator = this.slidesGenerator.bind(this);
    }

    slidesGenerator(subLogos, colLg, colMd, colXs, logosArr) {

        let slidesArr = [];
        let subsetArr, colArr, rowArr;
    
        while (logosArr.length > 0) {
            
            subsetArr = logosArr.splice(0, subLogos);
            
            colArr = subsetArr.map( (logo, i) => {
                return (
                    <Col className="logo-col-css" key={i} lg={colLg} md={colMd} xs={colXs}>
                        <img className="logo-css" src={logo.location} alt={`${logo.name} Logo`} />
                    </Col>
                );
            });
            
            if(colArr.length < subLogos) {
                for (let ind = colArr.length; ind < subLogos; ind++) { 
                    colArr.push (
                        <Col className="empty-logo-col-css" key={ind} lg={colLg} md={colMd} xs={colXs}></Col>
                    );
                }
            };
            
            rowArr = (<Row className="logo-row-css">{colArr}</Row>);
    
            slidesArr.push(rowArr);
        }

        return slidesArr;
    }

    render() {
        const {logosArr} = this.state;

        let logosArrCopy = [...logosArr];
        let slidesArr;

        let subLogos = 16, lg = 3, md = 0, xs = 0;
        if(window.innerWidth < 720) {
            subLogos = 4;
            lg = 0;
            md = 0;
            xs = 7;

        } else if (window.innerWidth < 1080 && window.innerWidth >= 720) {
            subLogos = 8;
            lg = 6;
            md = 6;
            xs = 6;
        } 
        slidesArr = this.slidesGenerator(subLogos, lg, md, xs, logosArrCopy);

        const carouItems = slidesArr.map ( (slide, index) => {
            return (<Carousel.Item key={index}>{slide}</Carousel.Item>);
        });

        let carouselCount = carouItems.length;

        return(
            <Carousel 
                className="carousel-bkgrd" 
                interval={null}
                prevIcon={(<img src={prevArrow} className="prev-arrow-css" alt="red previous arrow" />)}
                nextIcon={(<img src={nextArrow} className="next-arrow-css" alt="red next arrow" />)}
                key={carouselCount}
            >
                {carouItems}
            </Carousel>
        );
    };
}

export default CanMembersCarousel;
