export default [
  {
    headline: "Can Apprenticeships Help Close U.S. Skills Gaps?",
    image: "accenture-julie-sweet.png",
    // article image is placeholder, article uses video
    imageDescription: 'Smiling Woman',
    source: "Fortune - 2019",
    linkTo: "https://fortune.com/2019/09/03/can-apprenticeships-help-close-u-s-skills-gaps/"
  },
  {
    headline: "Counselor: Students ‘Excited’ About Insurance After Zurich’s Apprenticeship Summit",
    image: "Zurich-North-Amercia-Green-Headquarters-768x398.jpg",
    imageDescription: "Zurich Headquarters",
    source: "Insurance Journal - 2020",
    linkTo: "https://www.insurancejournal.com/news/midwest/2020/01/10/554027.htm"
  },
  {
    headline: "Rep. Ro Khanna Wants to Create a National Apprenticeship Program",
    image: "rokhanna.png",
    imageDescription: "Ro Khanna in his office",
    source: "Time 2020",
    linkTo: "https://time.com/5790140/ro-khanna-new-apprentice-bill/"
  }
]