import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import "./style.css";

const EventCard = ({upcomingEvent}) => {

  const [month, day] = upcomingEvent.date.split(" ");

  return(
    <Container className="eventCard">
      <Row className="titleRow">
        <div className="dateBubble backgroundPrimary2">
          {(upcomingEvent.date === "TBD") ? 
            (<div className="tbdStyle">{upcomingEvent.date}</div>) :
            (<>
              <div className="monthStyle">{month}</div>
              <div>{day}</div>
            </>)
          }
        </div>
        <h2 className="upcomingEventTitle textPrimary3">{upcomingEvent.name}</h2>
      </Row>
      <Row>
        <Col as="p" className="upcomingEventDescription" md={{span: 8, offset: 2}}>
          {upcomingEvent.haveLink ? 
            (<div>
              {upcomingEvent.description}
              <a href={upcomingEvent.link} target="_blank" rel="noopener noreferrer">{upcomingEvent.linkName}</a>
            </div>) : 
            (upcomingEvent.description)
          }
        </Col>
      </Row>
    </Container>
  )
}

export default EventCard;
