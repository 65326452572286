import React, { useState, useEffect } from 'react';
import canLogo from "../../../assets/CAN_logo.jpg";
import closeIcon from "../../../assets/icons/closeIcon.png"
import menuIcon from "../../../assets/icons/menuIcon.png"
import "./CanNavbar.css";
import "../../../../src/App.css";

const CanNavbar = props => {
 //State to keep track of dropdown menu being displayed
 const [showMenu, setShowMenu] = useState(false);

 /**
  * Method to toggle visiblitiy of dropdown menu.
  * @param {*} event 
  */
 const toggleShow = (event) => {
     const currState = showMenu;
     setShowMenu(!currState);
 }

 //State to keep track of size of the display
 const [displaySize, setDisplaySize] = useState(window.innerWidth);

 //State to keep track of display for the menu
 // 'small' is used for mobile nav bar
 // 'large' is used for window nav bar
 const [displayType, setDisplayType] = useState('small');

 /**
  * Use effect hook to trigger re-render navigation bar if the screen size changes from small to large
  * or from large to small. 
  */
 useEffect(() => {
     if (displayType === 'large' && displaySize < 1100) {
         setDisplayType('small')
     } else if (displayType === 'small' && displaySize > 1100) {
         setDisplayType('large')
         setShowMenu(false);
     }

 }, [displaySize, displayType]);


 /**
  * Use effect hook to track and update display size state
  */
 useEffect(() => {
     // Handler to call on window resize
     function handleResize() {
         // Set window width/height to state
         setDisplaySize(window.innerWidth);
     }

     // Add event listener
     window.addEventListener("resize", handleResize);

     // Remove event listener on cleanup
     return () => window.removeEventListener("resize", handleResize);
 }, [displaySize, setDisplaySize]); // Empty array ensures that effect is only run on mount



 // Displays nav bar based on screen size. 
 return (
     <nav>
         {displayType === 'small' ?
         //MOBILE NAV BAR
             <div className="can-navbar">
                 <div className="can-navbar-btnholder">
                     <img
                     height="50"
                     className="d-inline-block align-top"
                     src={canLogo}
                     alt="Chicago Apprentice Network Logo"
                 />
                     <img
                         width="20"
                         height="20"
                         src={showMenu ? closeIcon: menuIcon}
                         alt="Menu Button"
                         float="right"
                         className="navbar-icon"
                         onClick={toggleShow}
                     /></div>

                 {showMenu && <div className="can-menu-dropdown" >
                     <li className="can-nav-btns height-sm"><a className="c-links lineheight-sm" href="/#/" onClick={toggleShow}>Home</a></li>
                     <li className="can-nav-btns height-sm"><a className="c-links lineheight-sm" href="/#/employers" onClick={toggleShow}>Employers</a></li >
                     <li className="can-nav-btns height-sm"><a className="c-links lineheight-sm" href="/#/careerseekers" onClick={toggleShow}>Career Seekers</a></li >
                     <li className="can-nav-btns height-sm"><a className="c-links lineheight-sm" href="/#/newsevents" onClick={toggleShow}>News & Events</a ></li >
                 </div>}
             </div>
             :
             //DESKTOP NAV BAR
             <div className="can-navbar">
                 <div className="can-navbar-btnholder">
                     <img
                     width="250"
                     height="70"
                     className="can-logo"
                     src={canLogo}
                     alt="Chicago Apprentice Network Logo"
                 />
                     <div className="nav-btns-large nav-margin">
                         <li className="can-nav-btns height-lg"><a className="c-links lineheight-lg" href="/#/" onClick={toggleShow}>Home</a></li>
                         <li className="can-nav-btns height-lg"><a className="c-links lineheight-lg" href="/#/employers" onClick={toggleShow}>Employers</a></li >
                         <li className="can-nav-btns height-lg"><a className="c-links lineheight-lg" href="/#/careerseekers" onClick={toggleShow}>Career Seekers</a></li >
                         <li className="can-nav-btns height-lg"><a className="c-links lineheight-lg" href="/#/newsevents" onClick={toggleShow}>News & Events</a ></li ></div>
                 </div>
             </div>}
     </nav>
 )
};

export default CanNavbar;

