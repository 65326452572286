import React, { useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import IndividualArticle from './IndividualArticle';
import './style.css';

//takes a list and puts it all under the readmore button
const YearArchive = ({year, articleArray}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  //this isn't the best way to do this for sure
  const currentYear = new Date();
  const thisYear = new Date(year, 11, 31); //JavaScript counts months from 0 to 11, so December is 11

  const expandArticles =() => {
    setIsExpanded(!isExpanded);
  };

  const articleDisplay = articleArray.map((article, i) => (
    <Col key={i}>
      <IndividualArticle article={article} index={i} />
    </Col>
  ));

  // THIS BLOCK MAKES IT SO THE CURRENT YEAR CAN'T HAVE AN ARCHIVE LIST
  if(thisYear >= currentYear) {
    return (
      <Container fluid className="wrapper">
        <Row xs={1} md={3}>{articleDisplay}</Row>
      </Container>
    );
  }

  return (
    <div>
      <h3 className="fullWidthUnderline wrapper">
        <button type="button" className="expandButton" onClick={expandArticles}>{year} Archives {isExpanded? "-" : "+"}</button>
      </h3>
      
      {isExpanded && <Container fluid className="wrapper">
        <Row xs={1} md={3}>{articleDisplay}</Row>
      </Container>}
    </div>
  )
}

export default YearArchive;
