import React from "react";
import Header from "../shared/header/Header.jsx";
import OurMission from "./ourMission/OurMission.jsx";
import Recognition from "./recognition/Recognition.jsx";
import GetInvolved from "./getInvolved/GetInvolved.jsx";
import MembersAndPartners from "./membersAndPartners/MembersAndPartners.jsx";
import BetteringChicago from "./betteringChicago/BetteringChicago.jsx";
import cityDesktop from "../../assets/banner-desktop.jpg";
import cityMobile from "../../assets/banner-mobile.jpg";
import { Row } from "react-bootstrap";
import zipCodeMap from "../../assets/chicagoBlue_redCropping.png";
import useWindowDimensions from "../shared/functions/headerBannerFormat.jsx";

const Home = () => {
  const img = useWindowDimensions().width >= 768 ? cityDesktop : cityMobile;
  
  return (
    <div>
      <Header
        img={img}
        text="Bridging the gap between talent and opportunity for Chicago's future"
        tagline="This website is proudly built and powered by apprentices."
      />
      <OurMission />
      <Row className="map">
        <BetteringChicago />
        <img className="cityMap1" src={zipCodeMap} alt="Chicago Greater Area Zip code coverage map" />
      </Row>
      <Recognition />
      <MembersAndPartners />
      <GetInvolved />
    </div>
  );
};

export default Home;
