export default [
  {
    text1: "Apprenticeship",
    text2: "Playbook",
    link:
      "https://s3.amazonaws.com/brt.org/Chicago-ApprenticeNetwork_BridgingtheGap_181222.pdf",
    quote:
      "Chicago Apprentice Network founders provide a step-by-step guide to setting up a successful apprenticeship program.",
    image: "/assets/startingYourOwnProgram/pdfImage.png",
    altText: "PDF Icon"
  },
  {
    text1: "Apprenticeship",
    text2: "Starter Guide",
    link:
      "/assets/membersAndPartners/Apprenticeship Starter Guide.pdf",
    quote:
      "Want a quick overview? The Chicago Apprentice Network Starter Guide offers highlights of the Apprenticeship Playbook.",
    image: "/assets/startingYourOwnProgram/pdfImage.png",
    altText: "PDF Icon"
  },
  {
    text1: "Apprenticeship.gov",
    text2: "Website",
    link: "https://www.apprenticeship.gov",
    quote:
      "The U.S. Department of Labor’s site provides a thorough look at the who, what, where, how and why of apprenticeship.",
    image: "/assets/startingYourOwnProgram/pinImage3.png",
    altText: "Paperclip Icon"
  }
];
