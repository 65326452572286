import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import "./style.css";
import EmployerBenefitsConstants from "../../../inputs/employersInputs/employerBenefits";

const EmployerBenefits = () => (
    <Container fluid className="wrapper top-margin">
        <Row className="empBenefitsBox">
            <Col xs={12} md={4}>
                <h1 className="headingUnderline benefits-heading">{EmployerBenefitsConstants.header}</h1>

                <h3 className="benefits-sub-heading">{EmployerBenefitsConstants.subHeader}</h3>

                <p className="textAccent3 ">{EmployerBenefitsConstants.firstSectionGeneralText}</p>
            </Col>

            <Col xs={11} md={3}  className="my-2   textPrimary3 circleBox" >
                <div className="text-center align-middle backgroundPrimary1 circle" >
                    <div className="text-container">
                        <h5 className="circle-text">Employers earn <span className="textPrimary2">$1.47</span> return for <span className="textPrimary2">every dollar invested</span> in apprenticeship.</h5>
                        <a href={EmployerBenefitsConstants.sourceNA} target="_blank" rel="noopener noreferrer" className="textAccent3 source-text" >Source: NationalApprenticeship.org</a>
                    </div>
                </div>
            </Col>

            <Col xs={11} md={3}   className="my-2  circle-two textPrimary3 circleBox" >
                <div className="text-center align-middle backgroundPrimary1 circle">
                    <div className="text-container">
                        <h5 className="circle-text">Over <span className="textPrimary2">90%</span> of apprentices who complete an apprenticeship are still with their employer <span className="textPrimary2">nine months later</span>.</h5>
                        <a href={EmployerBenefitsConstants.sourceDOL} target="_blank" rel="noopener noreferrer" className="textAccent3 source-text" >Source: DOL Website</a>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
);

export default EmployerBenefits;