import React from "react";
import "./style.css";

const ProfileCard = props => (
  <div className="profileCard">
    <img className="cardPhoto" src={props.image} alt={props.name} />
    <div className={"upperRectangle " + props.upperFormat}>
      <div className={"cardText1 " + props.text1Format}>
        <div className="quote">{props.quote}</div>
      </div>
    </div>
    <div className={"lowerRectangle " + props.lowerFormat}>
      <div className={"cardText3 " + props.text3Format}>
        {props.title}
        {props.url ? <a href={props.url} target="_blank" rel="noopener noreferrer"><h2 className={"cardText2 " + props.text2Format}>{props.name}</h2></a> : <h2 className={"cardText2 " + props.text2Format}>{props.name}</h2>}
      </div>
    </div>
  </div>
);

export default ProfileCard;
