import React from "react";
import inputMemberTestimonials from "../../../inputs/inputMemberTestimonials.js";
import ProfileCard from "../../shared/profileCard2/ProfileCard.jsx";
import {Container, Row, Col} from 'react-bootstrap'
import "./style.css";

const generateCards = () => {
    return inputMemberTestimonials.map((person, i) => {
        return (
            <Col xs={12} sm={12} md={4} lg={4} key={i}>
            <ProfileCard
                key={i}
                name={person.name}
                title={person.title}
                quote={person.quote}
                image={person.image}
                upperFormat="backgroundPrimary3"
                lowerFormat="backgroundWhite"
                text1Format="textWhite"
                text2Format="textPrimary2"
                text3Format="textAccent3"
            />
            </Col>
        );
    });
};

const MemberTestimonials = () => (
    <div>
        <h1 className="headingUnderline mt-heading wrapper">Member Testimonials</h1>
        <Container className="mt-container wrapper" fluid={true}><Row>{generateCards()}</Row></Container>
    </div>
);

export default MemberTestimonials
