import React from "react";
import "./style.css";

const PastEventCard = ({ pastEvent }) => {
  function generateDescriptionElements(event) {
    const elements = [];
  
    for (let i = 1; i <= 9; i++) {
      const descriptionKey = `description${i}`;
      const urlKey = `url${i}`;
      const anchorTextKey = `anchorText${i}`;
  
      if (event[descriptionKey]) {
        
        const description = event[descriptionKey];
        const url = event[urlKey];
        const anchorText = event[anchorTextKey];
  
        let formattedDescription = description.replace(
          /Chicago Apprenticeship Network/g,
          '<strong>Chicago Apprenticeship Network</strong>'
        );
  
        formattedDescription = formattedDescription.replace(
          /Northwestern Apprentice Graduates/g,
          '<strong>Northwestern Apprentice Graduates</strong>'
        );

        formattedDescription = formattedDescription.replace(
          /hitting a milestone of creating 2,000 Apprenticeships in the Chicagoland area/g,
          '<strong>hitting a milestone of creating 2,000 Apprenticeships in the Chicagoland area</strong>'
        );
  
        const descriptionElement = (
          <React.Fragment key={i}>
            <span dangerouslySetInnerHTML={{ __html: formattedDescription }} />
            {' '}
            <a href={url} target="_blank" rel="noopener noreferrer">
              {anchorText}
            </a>
          </React.Fragment>
        );
  
        elements.push(descriptionElement);
      }
    }
  
    return elements;
  }
  
  
  
  return (
    <div>
      <div className="imageDiv">
        {pastEvent.url ? (
          <a href={pastEvent.url} target="_blank" rel="noopener noreferrer">
            <img
              className="pastEventImg"
              src={require(`./../../../assets/pastEventPhotos/${pastEvent.image}`)}
              alt={pastEvent.imageDescription}
            ></img>
          </a>
        ) : (
          <img
            className="pastEventImg"
            src={require(`./../../../assets/pastEventPhotos/${pastEvent.image}`)}
            alt={pastEvent.imageDescription}
          ></img>
        )}
      </div>
      <div className="infoDiv">
        <h3 className="eventTitle textPrimary3">{pastEvent.name}</h3>
        <p className="eventDescription">
          {pastEvent.description}{' '}
          <a href={pastEvent.url} target="_blank" rel="noopener noreferrer">
            {pastEvent.anchorText}
          </a>{' '}
          {generateDescriptionElements(pastEvent)}
        </p>
      </div>
    </div>
  );
};

export default PastEventCard;
