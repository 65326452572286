export default [
  // {
  //   title:
  //     'Big Chicago companies recruiting City Colleges students as apprentices',
  //   source: 'CBS NEWS - 8/2022',
  //   description:
  //     "As college students head back to class, some in Chicago are headed to the office as part of the Chicago Apprentice Network, a program that recruits talented students at community colleges for some of the city's most prestigious companies. ",
  //   url: 'https://www.youtube.com/watch?v=8DAlN7QnPso',
  //   anchorText: 'Read More...'
  // },
  {
    title:
      'Chicago Apprentice Network: Scaling and Replicating a Successful Apprentice Network Model',
    source: 'BUSINESS ROUNDTABLE 12/2023',
    description:
      "Professional apprenticeship programs are an innovative method of talent acquisition that mirror apprenticeships historically used in fields such as advanced manufacturing and other skilled trades. Apprenticeship programs help address today’s skills needs and talent shortages while furthering efforts around diversity, equity and inclusion. ",
    url: 'https://www.businessroundtable.org/chicago-apprentice-network-scaling-and-replicating-a-successful-apprentice-network-model',
    anchorText: 'Read More...'
  }
];
