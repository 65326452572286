import React from "react";
import inputMembersAndPartners from "../../../inputs/inputMembersAndPartners";
import "./style.css";
import { Container, Row, Col } from "react-bootstrap";
import CanMembersCarousel from "./cancarousel/CanMembersCarousel";

const generateLogos = section => {
  return inputMembersAndPartners[section].map((logo, i) => {
    return (
      <Col className="logoBlock justify-content-center" key={i} md={3} xs={6}>
        <img className="logoInd" src={logo.location} alt={`${logo.name} Logo`} />
      </Col>
    );
  });
};

const allLogos = () => {
  let logoSections = Object.keys(inputMembersAndPartners).map((key, i) => {
    return (
      <React.Fragment key={i}>
        <Row>
          <Col>
            <h4 className="memberUnderline allCaps">{key}</h4>
          </Col>
        </Row>
        <Row className="containerLogo" lg={4} md={4}>
          {generateLogos(key)}
        </Row>
      </React.Fragment>
    );
  });
  
  logoSections.shift();
  return logoSections;
};

const MembersAndPartners = () => (
  <Container fluid className="mem wrapper">
    <Row>
      <Col><h4 className="memberUnderline allCaps">{"Chicago Apprentice Network Members"}</h4></Col>
    </Row>
    <CanMembersCarousel logos={inputMembersAndPartners["Chicago Apprentice Network Members"]} />

    {allLogos()}
  </Container>
);

export default MembersAndPartners;
