import React from "react";
import "./style.css";

const ButtonSocial = props => (
  <a href={props.link} target="_blank" rel="noopener noreferrer">
    <button className="buttonSoc textWhite">
      <picture>
        <source srcSet={`${props.img} `} media="(min-width: 768px)" />
        <img className="btnSocImg" srcSet={`${props.imgMobile} `} alt={props.altText} />
      </picture>
      <span className="btnSocName">{props.name}</span>
    </button>
  </a>
);

export default ButtonSocial;
