import React from "react";
import { Row, Col } from "react-bootstrap";
const NewModal = ({ open, onClose }) => {
  if (!open) return null;

  return (
    <div className="modal-wrapper">
      <div className="modal-content">
        <Row>
          <Col>
            <h1 className="headingUnderline trans-head">VIDEO TRANSCRIPT</h1>
          </Col>
          <Col>
            <button className="close" onClick={onClose}>
              &times;
            </button>
          </Col>
        </Row>
        <div className="trans-text">
          Victor Gutierrez (Aon apprentice):
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When I was at a hotel working as
          a bellman, I would see various businesspeople coming in. They'd be
          wearing suits, going off to meetings. They seemed to really enjoy
          themselves. And I always thought to myself that that's what I want to
          be. I want to do that. And now that I'm in the apprenticeship program,
          that's what I do. I get the opportunity to go every day into work and
          truly be excited for what I'm doing.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; My name is Victor Gutierrez. I'm
          an apprentice at Aon. I work in the U.S. risk office. I've done this
          for a year now, so that's the halfway point of the two-year program.
          I've been given many responsibilities and I'm happy to know that I
          really am making an impact not only with my team but in Aon itself.
        </div>
        <div className="trans-text">
          Bridget Gainer (VP, Global Public Affairs, Aon):
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; For years, Aon hired from all the
          places that most large companies typically hire from - the big
          universities around the Midwest. But several years ago, we started
          working with Harold Washington. First, it was internships and we
          helped them with their curriculum. And we realized that there was a
          huge pipeline of talent that we didn't really have access to. And you
          know the irony for us is I can see Harold Washington College from my
          office, but we had never hired anyone there.
        </div>
        <div className="trans-text">
          Juan Salgado (Chancellor, City Colleges of Chicago):
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our students at City Colleges of
          Chicago - they're hungry; they're hard-working; they're
          entrepreneurial. They come with capabilities that we see every day in
          our environments. But that we also know when they get into the
          companies like Aon and Accenture, they will thrive.
        </div>
        <div className="trans-text">
          Jim Coleman (Sr. Managing Director, Chicago, Accenture):
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our apprentices do a variety of
          things working mostly within our IT department: areas of
          communications, IT support, IT development. For we really value the
          diverse skills and the life experiences that our apprentices are
          bringing to the organization.
        </div>
        <div className="trans-text">
          Juan Salgado:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I see the city of Chicago
          embracing this apprenticeship model. I see so many more companies
          joining the Apprenticeship Network. I see thousands of students
          benefiting from apprenticeships across the city of Chicago in every
          single industry.
        </div>
        <div className="trans-text">
          Bridget Gainer:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; What we've realized is not only
          can we have an impact on the lives of young people across Chicago
          (which I think is tremendous), but we're having an impact on our
          company. We are bringing in talent from across the city, from every
          neighborhood. And that is going to make us a better and stronger
          company.
        </div>
        <div className="trans-text">
          Victor Gutierrez:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Through this opportunity, I feel
          that I am becoming a better student. I'm becoming a better worker. I'm
          becoming a better father, a better husband. All around I've really
          felt improvement in every aspect of my life.
        </div>
      </div>
    </div>
  );
};

export default NewModal;
