import React from "react";
import "./RecogCardCss.css";
import redQuote from "../../../assets/redQuotes.svg"

const RecogCard = (props) => {

    const {upperFormat, lowerFormat, quote, name, title, image,
    quoteFormat, nameFormat, titleFormat, linkUrl} = props;

    return(
        <div className="recog-card" >
            <div className={"recog-upper " + upperFormat}>
                <div className={quoteFormat}>{quote}</div> {/*div exist to help center the quote vertically and horizontally*/}
            </div>
            <img className="red-quote" src={redQuote} alt="red right double quote" />
            <div className={"recog-lower " + lowerFormat}>
                <img className="recog-img" src={image} alt={name} />
                {/*wrap <a> around div to control if name is too long, since it won't get stylized to left if just <a>*/}
                <a href={linkUrl} target="_blank" rel="noopener noreferrer" className="recog-name-link">
                    <div className={"recog-name " + nameFormat}>{name}</div>
                </a>
                <div className={"recog-title " + titleFormat}>{title}</div>
            </div>
        </div>
    );
}

export default RecogCard;