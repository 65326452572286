import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProgramCard from "../../shared/programCard/ProgramCard";
import inputExplorePrograms from "../../../inputs/inputExplorePrograms";
import "./style.css";

const generateCards = () => {
  return inputExplorePrograms.nonProfits.map((nonProfit, i) => {
    return (
      <Col xs={12} sm={12} md={4} lg={4} key={i}>
        <ProgramCard
          className="ep-logo"
          key={i}
          image={nonProfit.logo}
          title={nonProfit.title}
          desc={nonProfit.desc}
          learnMoreURL={nonProfit.learnMoreURL}
        />
      </Col>
    );
  });
};

const NonProfits = props => {
  return (
    <div className="ep-container-main">
      <div className="ep-text wrapper">Non-Profit Partners</div>
      <Container className="ep-container wrapper" fluid={true}>
        <Row>{generateCards()}</Row>
      </Container>
    </div>
  );
};

export default NonProfits;
