import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProgramCard from "../../shared/programCard/ProgramCard";
import inputExplorePrograms from "../../../inputs/inputExplorePrograms";
import "./style.css";

const generateCards = () => {
  return inputExplorePrograms.CommunityColleges.map((college, i) => {
    return (
      <Col xs={12} sm={12} md={4} lg={4} key={i}>
        <ProgramCard
          key={i}
          image={college.logo}
          title={college.title}
          desc={college.desc}
          learnMoreURL={college.learnMoreURL}
        />
      </Col>
    );
  });
};

const CommunityColleges = () => (
  <div className="ep-container-main">
    <h1 className="headingUnderline ep-heading wrapper">EXPLORE PROGRAMS</h1>
    <p className="ep-para wrapper">{inputExplorePrograms.CommunityText}</p>
    <div className="ep-text wrapper">Education Partners</div>
    <Container className="ep-container wrapper" fluid={true}>
      <Row>{generateCards()}</Row>
    </Container>
  </div>
);

export default CommunityColleges;
