import React from "react";
import inputExploreOpportunities from "../../../inputs/inputExploreOpportunities.js";
import ProgramCard from "../../shared/programCard/ProgramCard.jsx";
import setRecruitingFlag from "./setRecruitingFlag.js";
import { Row, Col, Container } from "react-bootstrap";
import "./style.css";

const generateCards = () => {
  return inputExploreOpportunities.map((company, i) => {
    return (
      <Col xs={12} sm={12} md={4} lg={4} key={i}>
        <ProgramCard
          key={i}
          image={company.image}
          title={company.title}
          desc={company.desc}
          isRecruiting={setRecruitingFlag(company.recruiting)}
          learnMoreURL={company.learnMoreURL}
          recruiting={company.recruiting}
          program_start={company.program_start}
          program_length={company.program_length}
          recruiting_sources={company.recruiting_sources}
          hasContact={company.hasContact}
          contactData={company.contactData}
        />
      </Col>
    );
  });
};

const ExploreOpportunities = () => (
  <div className="eo-container-main">
    <h1 className="headingUnderline eo-heading wrapper">EXPLORE OPPORTUNITIES</h1>
    <Container className="eo-container wrapper" fluid={true}>
      <Row>{generateCards()}</Row>
    </Container>
  </div>
);

export default ExploreOpportunities;
