import React from 'react';
import './style.css';

const LineSeparator = (props) => {
	let lineBackgroundColor = props.background || 'default-line-background';
	return (
		<div className={lineBackgroundColor}>
			<div className="ep-line-separator"/>
		</div>
	);
}

export default LineSeparator;