import React from "react";
import inputApprenticeshipPathways from "../../../inputs/inputApprenticeshipPathways.js";
import TestimonialCard from "../../shared/TestimonialCard/TestimonialCard";
import { Container, Row, Col } from "react-bootstrap";
import "./style.css";

const generateCards = () => {
  return inputApprenticeshipPathways.map((person, i) => {
    return (
      <Col xs={12} sm={12} md={4} lg={4} key={i}>
        <TestimonialCard
          key={i}
          name={person.name}
          title={person.title}
          quote={person.quote}
          image={person.image}
          url={person.url}
          upperFormat="backgroundPrimary3"
          lowerFormat="backgroundWhite"
          text1Format="textWhite"
          text2Format="textPrimary2"
          text3Format="textAccent3"
        />
      </Col>
    );
  });
};

const ApprenticeshipPathways = () => (
  <>
    <p className="apd-para-heading wrapper">Apprenticeship pathways are diverse.</p>
    <p className="apd-para-one wrapper">
      Access points for apprenticeship vary. Some opportunities are offered
      through a community college or other education provider. Some employers
      accept applications directly, during specific periods or ongoing
      throughout the year. Other opportunities originate with nonprofits, which
      refer candidates to businesses offering apprenticeships. Explore the
      avenues here.
    </p>
    <Container className="apd-container wrapper" fluid={true}>
      <Row>{generateCards()}</Row>
    </Container>
  </>
);

export default ApprenticeshipPathways;
