import React from "react";
import "./style.css";
import { Media } from "react-bootstrap";

const YourOwnProgramCard = ({ programList }) => {
  return (
    <div>
      <Media>
        <a href={programList.link} target="_blank" rel="noopener noreferrer">
          <img
            className="mr-3 pdf-img"
            src={programList.image}
            alt={programList.altText}
          />
        </a>
        <h2 className="textAccent3 text">
          {programList.text1}
          <br />
          {programList.text2}
        </h2>
      </Media>
      <p className="textAccent3">{programList.quote}</p>
    </div>
  );
};

export default YourOwnProgramCard;
