import React from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import NewsStoryCard from './NewsStoryCard';
import './style.css';
import inputNewsStory from '../../../inputs/inputNewsStory'

const NewsStory = () => {
    const newsStoryList = inputNewsStory;

    const newsStoryCards = newsStoryList.map((newsStoryInfo, i) => (
    <Col key={i} className="newsStoryCol">
      <NewsStoryCard newsStoryInfo={newsStoryInfo} />
    </Col>
    ))
  
    return (
      <Container className="newsStory" fluid>
        <Row className="newsStoryArticles"> 
            {newsStoryCards}
        </Row>
      </Container>
    )
  }

export default NewsStory
