import React from "react";
import prevArrowRed from "../../../assets/prevArrowRed.png";
import nextArrowRed from "../../../assets/nextArrowRed.png";
import inputRecognition from "../../../inputs/inputRecognition.js";
import "./style.css";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import RecogCard from "./RecogCard";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

/**
 * Generates a profile/quoted card for a person in the InputRecognition array.
 * More specifically, it generates n amount of cards based on the min and
 * max, and depending on the index (of InputRecognition). 
 * In short, its use is generating n amount of cards for a slide/row in the
 * carousel.
 *
 * @param {integer} min the index for the first (leftmost) card of a row/slide
 * @param {integer} max the index for the last (rightmost) card of a row/slide
 * @returns an array of a specfied number of Profile cards
 */
const generateCards = (min, max) => {
  return inputRecognition.map((person, i) => {
    if (i < max && i >= min) {
      return (
        <RecogCard
          key={i}
          name={person.name}
          title={person.title}
          quote={person.quote}
          image={person.image}
          linkUrl={person.linkUrl}
          upperFormat="backgroundPrimary3"
          lowerFormat="backgroundWhite"
          quoteFormat="textWhite"
          nameFormat="din-condensed-font"
          titleFormat="textAccent3"
        />
      );
    }
  });
};

/**
 * This generates the slide/item/row of the Carousel. It also controls
 * the amount of cards to be displayed per slide, and the number of slides
 * in the carousel, depending on the inputRecognition.
 * 
 * @param {integer} cardPerRow the number of cards/people to be displayed on a slide/row/item
 * @returns an array of slides/items/rows to be used in the <Carousel> component
 */
const generateItems = (cardPerRow) => {
  let min = 0;
  let max = cardPerRow;

  let numOfRows = Math.ceil(inputRecognition.length / cardPerRow);
  let html = Array.apply(null, Array(numOfRows)).map(function () { });
  return html.map((val, i) => {
    let tempMin = min;
    let tempMax = max;
    min += (cardPerRow);
    max += (cardPerRow);
    return (
      <Carousel.Item key={i}>
        <Row className="justify-content-center fill">
          {generateCards(tempMin, tempMax)}
        </Row>
      </Carousel.Item>
    );
  });
};

const Recognition = () => {

  const screenWidth = useWindowDimensions().width; //gets the window's screen width with the custom-made hook useWindowDimensions()
  let cardsPerRow = 3; //so for window size >= 1080 (desktop)
  if (screenWidth < 720) {
    cardsPerRow = 1 //for mobile
  } else if (screenWidth < 1080 && screenWidth >= 720) {
    cardsPerRow = 2 //for tablet
  }

  //this is if inputRecognition have <= 3 people, remove the controls and indicators
  let showArrows = inputRecognition.length <= 3 ? false : true; //for desktop
  let showIndicators = inputRecognition.length <= 3 ? false : true;
  if (screenWidth < 720 && inputRecognition.length <= 3) { //for mobile
    showArrows = inputRecognition.length === 1 ? false : true; //so if there is 1 person only
    showIndicators = inputRecognition.length === 1 ? false : true;

  } else if (screenWidth < 1080 && screenWidth >= 720 && inputRecognition.length <= 3) { //for tablet
    showArrows = inputRecognition.length <= 2 ? false : true;
    showIndicators = inputRecognition.length <= 2 ? false : true;
  }

  const cardSlides = generateItems(cardsPerRow);
  let slidesLength = cardSlides.length;

  return (
    <Container fluid className="rec">
      <Row>
        <Col className="wrapper">
          <h4 className="headingUnderline">
            RECOGNITION
          </h4>
        </Col>
      </Row>
      <Row>
        <Carousel 
          interval={null}
          indicators={showIndicators}
          nextIcon={(<img src={nextArrowRed} className="recog-next-arrow" alt="red next arrow" />)}
          prevIcon={(<img src={prevArrowRed} className="recog-prev-arrow" alt="red previous arrow" />)}
          controls={showArrows}
          key={slidesLength}
          className="recog-carou"
        >
          {cardSlides}
        </Carousel>
      </Row>
    </Container>
  );
}

export default Recognition;
