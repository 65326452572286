export default {
  stats1: {
    number: '50+',
    text: 'Zip Codes Represented'
  },
  stats2: {
    number: '2000+',
    text: 'Apprenticeships Committed'
  },
  stats3: {
    number: '110+',
    text: 'Participating Companies'
  },
  stats4: {
    number: '18',
    text: 'Industries Represented'
  }
};
