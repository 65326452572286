//function requires the event's month, day, and YEAR in order to check if it's expired or not
//so when updating/adding upcoming events, please add in the YEAR in the date property
//ex. : Nov 21 2020 (with NO commas)
const isDateExpired = (eventMonth, eventDay, eventYr) => {
    if(eventMonth === "TBD") {
        return false;
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthNum = months.indexOf(eventMonth);
    const eventDate = new Date(eventYr, monthNum, eventDay);
    const currentDate = new Date();

    return eventDate < currentDate;
}

export default isDateExpired;
