import React from "react";
import { Row, Col, Container} from "react-bootstrap";
import "./style.css";
import startingOwnProgram from  "../../../inputs/ownProgramText";
import YourOwnProgramCard from './YourOwnProgramCard';

const OwnProgram = () => {
const yourOwnProgramList = startingOwnProgram.map((programList,i) => ( 
  <Col key={i}>
    <YourOwnProgramCard programList={programList} />
    </Col>
));

  return (
    <React.Fragment> 
      <div className="wrapper">
        <h1 className="headingUnderline start-heading">
          Starting Your Own Program
        </h1>
      </div>
      <Container fluid className="sp-container wrapper">
        <Row xs={1} md={3} className="styleRow">
          {yourOwnProgramList}
        </Row>
      </Container>
    </React.Fragment> 
  )
};

export default OwnProgram;
