export default [
  {
    name: "Julie Sweet",
    title: "Accenture CEO",
    quote:
      '"By rallying employers to invest in apprenticeships, we can unleash the untapped potential of millions."',
    image: "/assets/corporateLeaders/julieSweet.png",
  },
  {
    name: "Kristof Terryn",
    title: "Zurich North America CEO",
    quote:
      '"We continue to invest in our trailblazing apprenticeship program because our apprentices contribute meaningfully to our business success."',
    image: "/assets/corporateLeaders/kristofTerryn.png",
  },
  {
    name: "Greg Case",
    title: "Aon CEO",
    quote:
      '"Our apprenticeship program has far exceeded our expectations in terms of its impact on both our firm and Chicago."',
    image: "/assets/corporateLeaders/gregCase.png",
  },
];
