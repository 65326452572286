import React from "react";
import NewModal from "./NewModal";
import inputWelcomeToNewCareer from "../../../inputs/inputWelcomeToNewCareer";
import OutsideClickHandler from "react-outside-click-handler";
import { Button, Row, Col, Container } from "react-bootstrap";
import "./style.css";

const WelcomeToNewCareer = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Container className="wnc-container wrapper top-margin" fluid={true}>
      <Row>
        <Col xs={12} sm={12} md={7} lg={7}>
          <div className="wnc-video-container">
            <iframe
              className="wnc-iframe"
              src={inputWelcomeToNewCareer.videoURL}
              title="Career Seeker Video"
            ></iframe>
            <Button
              className="wnc-video-heading"
              variant="link"
              id="video-transcript-btn"
              onClick={() => setIsOpen(true)}
            >
              VIDEO TRANSCRIPT
            </Button>
            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
              <NewModal open={isOpen} onClose={() => setIsOpen(false)} />
            </OutsideClickHandler>
          </div>
        </Col>
        <Col xs={12} sm={12} md={5} lg={5}>
          <h3 className="wnc-heading">{inputWelcomeToNewCareer.heading}</h3>
          <p className="wnc-desc">{inputWelcomeToNewCareer.desc}</p>
          <Button
            className="wnc-button"
            variant="danger"
            onClick={() =>
              window.open(
                "/assets/membersAndPartners/Apprenticeship Starter Guide.pdf",
                "_blank"
              )
            }
          >
            Apprenticeship Guide PDF
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default WelcomeToNewCareer;
