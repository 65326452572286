import React from "react";
import "./testimonial.css";

const ProfileCard = props => (
  <div className="profileCardT">
    <img className="cardPhotoT" src={props.image} alt={`${props.name}`} />
    <div className={"upperRectangleT " + props.upperFormat}>
      <div className={"cardText1T " + props.text1Format}>
        <div className="quoteT">{props.quote}</div>
      </div>
    </div>
    <div className={"lowerRectangleT " + props.lowerFormat}>
      <div className={"cardText3T " + props.text3Format}>
        {props.title}
        {props.url ? <a href={props.url} target="_blank" rel="noopener noreferrer"><h2 className={"cardText2T " + props.text2Format}>{props.name}</h2></a> : <h2 className={"cardText2T " + props.text2Format}>{props.name}</h2>}
      </div>
    </div>
  </div>
);

export default ProfileCard;