import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import EventCard from './EventCard';
import inputUpcomingEvents from "../../../inputs/inputUpcomingEvents.js";
import isDateExpired from './isDateExpired';
import './style.css'

import 'bootstrap/dist/css/bootstrap.min.css';

const UpcomingEvents = () => {
  const eventList = inputUpcomingEvents;

  const eventCardList = eventList.map((upcomingEvent, i) => {
    const [month, day, year] = upcomingEvent.date.split(" ");
    const expiredDate = isDateExpired(month, day, year);
    if(expiredDate) {
      return '';
    } else {
      return (
        <Col key={i} className="eventCardOuterCol">
          <EventCard upcomingEvent={upcomingEvent} />
        </Col> 
      );
    }
  }
  );

  return(
    <div>
      <h1 className="headingUnderline headingEventsPage top-margin wrapper">Upcoming Events</h1>
      <Container className="eventHolder wrapper" fluid>
        <Row xs={1} md={2}>{eventCardList}</Row>
      </Container>
    </div>
  )
}

export default UpcomingEvents;
