export default [
  {
    header: "Pritzker Speaks on Apprenticeships",
    copy: `Pritzker - "From my experiences as a businessman, I know that job creation efforts are strongest when government and business work together.

    During National Apprenticeship Week, we're going to celebrate all we've gotten done to ensure workers are prepared for the jobs of today and tomorrow."`,
    source: "JB Pritzker - Twitter",
    link: "https://twitter.com/govpritzker/status/1194394486945861632?s=12",
    date: "11/2019",
    isPaywalled: false
  },
  {
    header:
      "The best way to help companies find workers with the right skills? Apprenticeships, a new report says",
    copy: `For years, American employers have complained that there aren’t enough workers with the skills they need. At the same time, researchers have argued that employers should invest in workforce training to meet their needs.

    Now, a new report brings attention to a possible way to solve tech-skills gaps.

    On Thursday, professional services firm Accenture released a study titled “Apprenticeships: Building Pathways from Community College to Promising Tech Career,” which makes the case that community college students and apprenticeships can help solve tech-skills gaps.`,
    source: "CNBC",
    link:
      "https://www.cnbc.com/2019/10/31/apprenticeships-are-best-way-to-solve-skills-gap-says-accenture.html",
    date: "10/2019",
    isPaywalled: false
  },
  {
    header:
      "Apprentice Network Creates a Pipeline of Diverse Talent in Chicago, and Leaders Hope to Expand It",
    copy: `An apprentice network that started in Chicago to train future employees for the corporate sector as they pursue a free college education is now addressing larger social issues, and leaders of the initiative hope to spread the model to more cities around the country.

    Chicago’s first Black female mayor, Lori Lightfoot, lauded the Chicago Apprentice Network at an event recently. The network, founded in 2017 as a joint effort by Aon and Accenture, now connects roughly 485 apprentices to 25 companies in Chicago alone, including Walgreens, McDonald’s, and JPMorgan Chase.`,
    source: "Insight Into Diversity",
    link:
      "https://www.insightintodiversity.com/apprentice-network-creates-a-pipeline-of-diverse-talent-in-chicago-and-leaders-hope-to-expand-it/",
    date: "09/2019",
    isPaywalled: false
  },
  {
    header:
      "How companies use apprenticeships to build alternative career paths ",
    copy: `While the two- or four-year college track to jobs has persisted, it may no longer be relevant for employers struggling with talent gaps.

    In a challenging talent market, it's important to be flexible, prompting many to ask whether the four-year degree is necessary for certain entry-level positions. A recent poll by Adecco found 37% of employers are currently loosening their job requirements for temporary workers, and another 72% have done so in the past year.

    For some companies, the answer is apprentices — recruited from local colleges.`,
    source: "HR Drive",
    link:
      "https://www.hrdive.com/news/how-companies-use-apprenticeships-to-build-alternative-career-paths/561698/",
    date: "08/2019",
    isPaywalled: false
  },
  {
    header: "Can Apprenticeships Help Close U.S. Skills Gaps?",
    copy: `Loyda Villate was happily building a career as an architect when the global economy collapsed in late 2008. The Chicago firm where she worked laid off 40 of its 100 staffers, including her. What was her next move? “I wanted a career with more job security, so I decided to go back to school and learn something new,” she says now. “I asked a friend of mine in tech, and she told me, ‘Go into cybersecurity. There are never enough skilled people to go around.'”

    That proved to be a good career change. Fast forward to the present, and Villate is now an analyst on a cybersecurity team that works with internal customers at Accenture. The company signed her up while she was studying for an information-technology certificate at Wilbur Wright Community College in Chicago, through an apprenticeship program Accenture launched in 2016.`,
    source: "Fortune",
    link:
      "https://fortune.com/2019/09/03/can-apprenticeships-help-close-u-s-skills-gaps/",
    date: "09/2019",
    isPaywalled: false
  },
  {
    header: "Mayor Lori Lightfoot Speaks at Chicago Apprentice Network Event",
    copy:
      "REMARKS AT CHICAGO APPRENTICE NETWORK EVENT: Mayor Lightfoot speaks to business professionals at Chicago Apprentice Network's quarterly meeting.",
    source: "Lori Lightfoot - Twitter",
    link: "https://twitter.com/chicagosmayor/status/1156692837217386497?s=12",
    date: "07/2019",
    isPaywalled: false
  },
  {
    header:
      "Lightfoot and Pritzker tout apprenticeships to make sure talented local residents get jobs at local companies",
    copy: `Earning a degree is expensive. Earning a degree as a single mother is harder. And for Tiffany Spraggins, who wanted to be a computer technician, it seemed downright impossible.

    Last week, though, the 31-year-old started a full-time tech job at consulting giant Accenture, working on the company’s internal website. Spraggins, who plans to graduate from City Colleges of Chicago next spring, found her way into a tech career through an apprenticeship.`,
    source: "Chicago Tribune",
    link:
      "https://www.chicagotribune.com/business/ct-biz-lightfoot-tech-apprenticeship-program-20190715-i2mc4fiyd5fqdj54yw7owarviu-story.html",
    date: "07/2019",
    isPaywalled: true
  },
  {
    header: "US higher education crisis: lessons from the Chicago schools",
    copy: "",
    source: "Financial Times",
    link: "https://www.ft.com/content/973340fc-458b-11e9-b168-96a37d002cd3",
    date: "03/2019",
    isPaywalled: true
  },
  {
    header: "We have a once-in-a-generation opportunity to fix our labor market",
    source: "Washington Post",
    copy:
      "Behind the pages of statistics in the latest — and very robust — Labor Department jobs report is a data point that hints at both a stark reality and a once-in-a-generation opportunity: For the first time on record going back to 2000, the United States has more job openings — 7 million — than there are unemployed individuals to fill them. This is the largest mismatch ever recorded, but the disconnect goes deeper than connecting employers and job seekers.",
    link:
      "https://www.washingtonpost.com/opinions/2019/01/24/we-have-once-in-a-generation-opportunity-fix-our-labor-market/",
    date: "01/2019",
    isPaywalled: true
  },
  {
    header: "Why Penny Pritzker is bullish on apprenticeships",
    copy:
      "A $3.2 million fund designed to boost apprenticeships launches today and with a big name behind it: Former U.S. Secretary of Commerce Penny Pritzker. Her and her husband's Pritzker Traubert Foundation is one of eight local organizations taking part in Apprenticeship 2020, a collaborative effort that aims to hire 350 apprentices over two years. The ultimate goal is 1,000 apprenticeship positions in the Chicago area by the end of 2020. The Chicago Apprentice Network, formed in 2017, has nearly 400 positions at area firms.",
    source: "Crain's Chicago",
    link:
      "https://www.chicagobusiness.com/nonprofits-philanthropy/why-penny-pritzker-bullish-apprenticeships",
    date: "01/2019",
    isPaywalled: true
  },
  {
    header:
      "Big financial names put financial muscle behind paid internships for low-income students",
    copy: `Invest For Kids, an annual investment conference/fundraising event, and Chicago Scholars, a nonprofit that helps low-income students graduate from college, have launched Emerge, a program that offers paid summer internships to college students from low-income families. Emerge started last summer with 20 students; this summer, it will place 100 students at Chicago-area professional services firms, government offices and nonprofits.

    The plan is for Emerge to grow to 1,000 students within five years, says Goldman Sachs executive Ron Levin, who with Ben Kovler, CEO of Green Thumb Industries, a medical marijuana company, co-founded Invest For Kids in 2009. The fall conference, featuring well-known investment managers such as Ken Griffin and Sam Zell, has raised about $13 million, all net, as Levin and Kovler underwrite all overhead expenses. `,
    source: "Crain's Chicago",
    link:
      "https://www.chicagobusiness.com/nonprofits-philanthropy/big-financial-names-put-financial-muscle-behind-paid-internships-low-income",
    date: "11/2018",
    isPaywalled: true
  },
  {
    header: "Mayor Rahm Emanuel: ‘We cannot lose sight of our common humanity’",
    copy: `In the Jewish faith when you get married, the new husband breaks the glass under the huppah to remind us that in moments of joy, life is fleeting and fragile. It is an image that came to mind over the weekend as 11 lives were stamped out in a hate-filled shooting at the Tree of Life Synagogue in Pittsburgh.

    The victims were not just practicing their faith in a higher power, they were practicing their faith in America — a nation founded on the principle of religious freedom.`,
    source: "Chicago Sun Times",
    link:
      "https://chicago.suntimes.com/2018/10/31/18429908/mayor-rahm-emanuel-we-cannot-lose-sight-of-our-common-humanity",
    date: "11/2018",
    isPaywalled: true
  },
  {
    header:
      "Former Commerce Secretary Penny Pritzker: How to Create the Workforce of the Future",
    copy: `There will be work for Americans in the future despite doom and gloom about automation and artificial intelligence—we just aren’t totally prepared for what that work will look like, according former Commerce Secretary Penny Pritzker.

    “I’m a big believer—there is work in the future,” Pritzker said at Fortune‘s Brainstorm Reinvent conference in Chicago Tuesday afternoon. “We’re not preparing our workforce for that.”`,
    source: "Fortune",
    link: "https://fortune.com/2018/09/25/penny-pritzker-automation-ai/",
    date: "09/2018",
    isPaywalled: false
  },
  {
    header: "Apprenticeship success in Chicago provides national model",
    copy: `Despite critiques of President Trump’s apprenticeship task force, programs that blend work and school are thriving in cities where opportunities seem stagnant for youth in poorer communities.

    One prominent example, The Chicago Apprentice Network, draws together companies such as Aon, Zurich, Accenture, Walgreens, and others to provide apprenticeships to local youth. Joined recently by J.P. Morgan, these companies provide structured, on-the-job learning for recent high school graduates plus free tuition toward an associate’s degree.`,
    source: "Washington Examiner",
    link:
      "https://www.washingtonexaminer.com/red-alert-politics/apprenticeship-success-in-chicago-provides-national-model",
    date: "07/2018",
    isPaywalled: false
  },
  {
    header: " Help wanted: Corporate apprenticeship sponsors",
    copy: `Every year Accenture adds 400 to 500 employees to its Chicago office. This year, for the first time, more of the new hires will come from City Colleges of Chicago than anywhere else. What's just as remarkable: Until two years ago, when it brought in five students from Wright College, the accounting giant did no recruiting from City Colleges. The latter-day discovery of community colleges is good for Accenture, providing a new source of talent as the labor market reaches full employment, particularly among university grads. It's perhaps even better for students who previously found the doors to a career in professional services locked.`,
    source: "Crain's Chicago",
    link:
      "https://www.chicagobusiness.com/article/20180330/ISSUE07/180339993/chicago-employers-should-create-office-place-apprenticeships",
    date: "03/2018",
    isPaywalled: true
  },
  {
    header:
      "Can This 200-Year-Old Approach Help Modern Companies Find New Talent?",
    copy: `In spite of shrinking unemployment numbers, many companies say they’re still having trouble finding workers with the skills necessary to fill specific roles. An April 2017 survey from CareerBuilder found that 60% of U.S. employers across a wide range of industries have job openings that stay open for 12 weeks or longer. The average cost of extended vacancies is more than $800,000 annually, and this inability to find candidates takes a toll on productivity, morale, and revenue, to name a few areas.`,
    source: "Fast Company",
    link:
      "https://www.fastcompany.com/40519461/can-this-200-yearold-approach-help-modern-companies-find-new-talent",
    date: "01/2018",
    isPaywalled: false
  },
  {
    header: "Why more employers are saying a two-year degree will do",
    copy: `Over four years, Oak Street Health in Chicago has grown from a single clinic to 24 treating older patients in Illinois, Indiana and Michigan. Hiring employees who hold two-year degrees, rather than bachelor's degrees, has helped Oak Street grow quickly.The company employs 1,007 people, 444 of whom hold jobs that don't require a bachelor's. They work as medical assistants, community relations managers, welcome coordinators and more. Many are bilingual, fluent in English and one of the languages, including Cantonese, Mandarin, Romanian, Polish and Spanish, that Oak Street clients speak.`,
    source: "Crain’s Chicago",
    link:
      "https://www.chicagobusiness.com/article/20171110/ISSUE01/171119986/two-year-degrees-becoming-acceptable-to-hirers",
    date: "11/2017",
    isPaywalled: true
  },
  {
    header: "Apprenticeship programs enjoy new life as a workplace solution",
    copy: `Rigoberto Velazquez had a bachelor's degree in psychology from DePaul University and a steady job at a home health agency, but wasn't sure what he really wanted to do with his life. So he started taking classes part-time at Wilbur Wright community college, part of the City Colleges of Chicago, where he fell in love with computers. Velazquez went on to earn an associate's degree in computer information systems, and soon found himself in a pilot apprenticeship program at Accenture, a professional services company, working in the information technology department alongside colleagues with advanced technology degrees.`,
    source: "Chicago Tribune",
    link:
      "https://www.chicagotribune.com/business/ct-biz-apprenticeship-expansion-1029-story.html",
    date: "10/2017",
    isPaywalled: true
  },
  {
    header:
      "Apprenticeships, long common in blue-collar industries, are coming to white-collar office work",
    copy: `Andrew Skelnik grew up in what he calls a “strong blue-collar background” in Chicago. His father was an electrician, his uncle was a carpenter and his first job out of high school was in the mailroom of a printing plant, where he worked his way up to become a pressman. The word “apprenticeship,” to him, meant learning a similar skilled trade: He was waitlisted for two he applied for at local unions earlier in his career. But after he began studying computer programming while working in a warehouse, Skelnik, 29, was approached by a career adviser at his community college about a different kind of apprenticeship. He was offered a chance to work for a year at Accenture, the consulting and business services giant, where he got training on software platforms and mentoring on “soft skills” like résumé writing as he continued to take classes at night.`,
    source: "Washington Post",
    link:
      "https://www.washingtonpost.com/news/on-leadership/wp/2017/10/20/apprenticeships-long-common-in-blue-collar-industries-are-coming-to-white-collar-office-work/",
    date: "10/2017",
    isPaywalled: true
  },
  {
    header:
      "Aon and Accenture Join Forces to Expand Apprenticeships Across Chicago",
    copy: `CHICAGO; Aug. 30, 2017 – In the latest sign that the push to create apprenticeships in professional services is catching on, Accenture (NYSE: ACN) announced it will expand its Chicago apprenticeship program modeled on Aon plc’s (NYSE: AON) highly successful program run in collaboration with the City Colleges of Chicago. U.S. Secretary of Labor Alexander Acosta praised both companies for bringing apprenticeships to the professional services sector.`,
    source: "Accenture",
    link:
      "https://newsroom.accenture.com/news/aon-and-accenture-join-forces-to-expand-apprenticeships-across-chicago.htm",
    date: "08/2017",
    isPaywalled: false
  },
  {
    header:
      "Accenture Teams with City Colleges in the College to Careers Initiative, Helping to Prepare Students for Careers in Information Technology",
    copy: `City Colleges of Chicago has announced a collaboration with Accenture (NYSE: ACN) to help enhance and shape information technology (IT) curriculum at City Colleges of Chicago, as part of the community college system’s College to Careers initiative.  Launched by Mayor Rahm Emanuel and Chancellor Cheryl Hyman, College to Careers aligns each City College with a high-demand career sector and partners faculty and staff with leading employers and four-year universities to prepare students for the tens of thousands of jobs coming to the Chicagoland region in fast-growing fields over the next decade.`,
    source: "Accenture",
    link:
      "http://www.ccc.edu/news/Pages/Accenture-Teams-with-City-Colleges-in-the-College-to-Careers-Initiative,--Helping-to-Prepare-Students-for-Careers-in-Inform.aspx",
    date: "04/2016",
    isPaywalled: false
  },
  {
    header:
      "APCIA teams up with P&C groups, DOL to launch Insurance Apprenticeship USA",
    copy:
      "At the Women & Diversity: Expanding Opportunities in Insurance conference in New York on Friday, Feb. 28, 2020, the American Property Casualty Insurance Association (APCIA) launched a new program focused on establishing insurance apprenticeship programs all across the country.",
    source: "Property Casual 360",
    link:
      "https://www.propertycasualty360.com/2020/03/03/apcia-teams-up-with-pc-groups-dept-of-labor-to-launch-insurance-apprenticeship-usa/?slreturn=20200728132634",
    date: "03/2020",
    isPaywalled: true
  },
  {
    header:
      "In 2020, companies took up the cause of racial equity. Now the challenge is accountability. ‘It really starts from the top.’",
    copy:
      "Before several Chicago-area companies pledged in 2020 to diversify their ranks in response to calls for racial equity, Beam Suntory was on its way to hiring its first chief diversity and inclusion officer.",
    source: "Chicago Tribune",
    link:
      "https://www.chicagotribune.com/business/ct-biz-chicago-companies-pledge-diversity-efforts-20210114-w4jznuv2l5a6heknr76t66kfmi-story.html",
    date: "01/2021",
    isPaywalled: true
  },
  {
    header: "Insurers Turn to Apprenticeships to Find Diverse, Next-Gen Workforce",
    copy: "Aon, Zurich North America and Accenture’s multi-employer Chicago Apprentice Network has been so successful, it is expanding to other industries.",
    source: "Best's Review",
    link: "http://news.ambest.com/articlecontent.aspx?refnum=305160&altsrc=43&_ga=2.240683525.702658653.1611947016-1238019655.1603717953",
    date: "02/2021",
    isPaywalled: false
  },
  {
    header: "4 years and 1,000 City College students later, Chicago Apprentice Program shines for both businesses, participants",
    copy: "The Chicago Apprentice Network started four years ago with a challenge from Chicago’s mayor at the time; would you hire some kids from City Colleges of Chicago to work at your blue chip company? Jim Coleman, the senior managing director at Accenture in Chicago, says the program is working.",
    source: "WGN News",
    link: "https://wgntv.com/news/chicago-news/4-years-and-1000-city-college-students-later-chicago-apprentice-program-shines-for-both-businesses-participants/",
    date: "06/2021",
    isPaywalled: false
  },
  {
    header: "Chicago Apprentice Program marks 1,000 signups",
    copy: "A city program to provide minorities and community college students the opportunities they need to succeed has just passed a major milestone. The Chicago Apprentice Program now has signed up one thousand apprentices since it was begun in 2017.",
    source: "WGN News",
    link: "https://www.youtube.com/watch?v=dWrmC3pS0Mg",
    date: "04/2021",
    isPaywalled: false
  },
  {
    header: "Apprenticeships Highlighted in National PBS Story",
    copy: "A national PBS story, featuring Accenture Chicago apprentice graduate Tiffany Spraggins, as part of the Future of Work series that explores monumental changes in the workplace and the long-term impact on workers, work culture, educators and communities.",
    source: "PBS",
    link: "https://www.pbs.org/wgbh/future-of-work/episodes/",
    date: "09/2021",
    isPaywalled: false
  },
  {
    header: "Apprenticeships are another way to 'hire' education",
    copy: "Employers in the Chicago Apprentice Network are adapting apprenticeship to the rapidly changing digital economy. They’re offering earn-while-you-learn pathways to promising careers that can help address talent shortages and close equity gaps, writes Zurich North America CEO Kristof Terryn.",
    source: "Crain’s Chicago Business",
    link: "https://www.chicagobusiness.com/equity/chicago-apprentice-network-helps-develop-diverse-talent",
    date: "09/2021",
    isPaywalled: false
  },
  {
    header: "Business Roundtable Launches New \"Apprenticeship Accelerator\" Corporate Initiative",
    copy: "Business Roundtable today announced the launch of its \"Apprenticeship Accelerator\" initiative. The Accelerator will help companies develop or scale their registered apprenticeship programs to expand opportunities to workers without a four-year college degree and to support apprentices’ development and advancement",
    source: "Business Roundtable",
    link: "https://www.businessroundtable.org/business-roundtable-launches-new-apprenticeship-accelerator-corporate-initiative",
    date: "07/2022",
    isPaywalled: false
  },
  {
    header: "Chicago corporation head talks one solution to navigating labor shortage",
    copy: "The head of a huge Chicago corporation sits down with Morning Insider Lauren Victory to explain one solution to navigating the labor shortage storm. \"It's pretty remarkable in my role to sort of see an opportunity for wins everywhere,\" said Aon CEO Greg Case talking about the company's apprenticeship program in a sit-down interview with CBS 2.",
    source: "CBS News",
    link: "https://www.cbsnews.com/chicago/news/chicago-corporation-head-talks-solution-to-navigating-labor-shortage/",
    date: "07/2022",
    isPaywalled: false
  },
  {
    header: "Jill Biden touts 'career-connected learning' in Chicago, calling education a nonpartisan 'American issue'",
    copy: "First lady Jill Biden met with students, teachers, and business leaders in the Chicago area Monday to kick off National Apprenticeship Week, highlighting education as a nonpartisan \"American issue\".",
    source: "Chicago Sun Times",
    link: "https://chicago.suntimes.com/education/2022/11/14/23459196/jill-biden-visits-chicago-calls-education-nonpartisan-american-issue",
    date: "11/2022",
    isPaywalled: false
  },
  {
    header: "Big Chicago companies recruiting City Colleges students as apprentices",
    copy: "As college students head back to class, some in Chicago are headed to the office as part of the Chicago Apprentice Network, a program that recruits talented students at community colleges for some of the city's most prestigious companies. ",
    source: "CBS NEWS",
    link: "https://www.youtube.com/watch?v=8DAlN7QnPso",
    date: "08/2023",
    isPaywalled: false
  }
];
