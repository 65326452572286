import React from 'react';
import dollarSign from './../../../assets/icon-open-dollar.png';
import flagstars from './../../../assets/repeat-grid-9.png'
import './style.css'

const IndividualArticle = ({article, index}) => {
  return (
    <div className="archivedArticle">
      {index > 2 && (
        <div className="spacerImage">
          <img className="chicagoFlag" src={flagstars} alt="Star border for content"></img>  
        </div>
      )}
      <h4 className="articleHeader">{article.header}</h4>
      <p className="articleInfo">{article.source} - {article.date}</p>
      <p className="articleCopy">{article.copy}</p>
      <div className="readMore">
        <a className="readMoreLink" href={article.link} target="_blank" rel="noopener noreferrer">Read More... </a>
        {article.isPaywalled && <img className="dollarSign" alt="dollar sign" src={dollarSign}></img>}
      </div>
    </div>
  )
}

export default IndividualArticle;
