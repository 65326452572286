
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const ButtonInvolved = props => (
  <Link to={props.link}>
    <button className="textWhite allCaps backgroundPrimary2 buttonInv">
      <div>
        <img src={props.img} alt={`${props.name} Icon`} />
      </div>
      {props.name}
    </button>
  </Link>
);

export default ButtonInvolved;
