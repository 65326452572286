import React from "react";
import Header from "../shared/header/Header.jsx";
import EmployerBenefits from "./benefitsForEmployers/BenefitsForEmployers";
import cityDesktop from "../../assets/banner-desktop.jpg";
import cityMobile from "../../assets/banner-mobile.jpg";
import OwnProgram from "./startingYourOwnProgram/OwnProgram.jsx";
import MemberTestimonials from "./memberTestimonials/MemberTestimonials.jsx";
import useWindowDimensions from "../shared/functions/headerBannerFormat.jsx";

const Employers = () => {
  const img = useWindowDimensions().width >= 768 ? cityDesktop : cityMobile;

  return(
    <div>
    <Header
      img={img}
      text="Demonstrate leadership for the future of work"
    />
    <EmployerBenefits />
    <MemberTestimonials />
    <OwnProgram />
    </div>
  );
}
export default Employers;
