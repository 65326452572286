import React from 'react';
import "./style.css";

const FeaturedNewsCard = ({featuredNewsInfo}) => {
  return (
    <div className="featuredNewsCard">
      <img className="featuredNewsImage" src={require(`./../../../../public/assets/featuredNewsImages/${featuredNewsInfo.image}`)} alt={featuredNewsInfo.imageDescription}></img>
      <h2 className="featuredNewsHeader"><a href={featuredNewsInfo.linkTo} target="_blank" rel="noopener noreferrer">{featuredNewsInfo.headline}</a></h2>
      <p className="featuredNewsSource">{featuredNewsInfo.source}</p>
   </div>
  )
}

export default FeaturedNewsCard;
