import React from "react";
import "./style.css";
import betteringStats from "../../../inputs/betteringStats";
import zipCodeMap from "../../../assets/chicagoBlue_redCropping.png";
import { Container, Row, Col } from "react-bootstrap";

const BetteringChicago = (props) => (
  <Container fluid className="padding">
    <Row className="rowText">
      <Col>
        <h4 className="headingUnderline">BETTERING CHICAGOLAND</h4>
      </Col>
    </Row>
    <br />
    <Row className="rowText">
      <Col className="boldText">We’re proud we have created more than 2,000 apprenticeships in the Chicagoland area -- doubling our original goal since our launch in 2017.</Col>
    </Row>
    <br />
    <Row className="rowText">
      <Col className="regFontBC">
        Now we want to help every company, large to small, to offer apprenticeships, benefiting from the infrastructure that has been built and the successful models to date. By combining paid, on-the-job training with targeted education,
        apprenticeship opens doors to promising careers and promising talent.
      </Col>
    </Row>
    <Row className="justify-content-center rowText">
      <Col className="justify-content-center">
        <img className="cityMap2" src={zipCodeMap} alt="Chicago Greater Area Zip code coverage map" />
      </Col>
    </Row>
    <Row className="justify-content-center rowText">
      <Col className="stats" xs="5">
        <Row>
          <Col>
            <span className="number">{betteringStats.stats1.number}</span>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={8}>
            <span className="textBC">{betteringStats.stats1.text}</span>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={8}>
            <span className="textSmallDetail">(shown in blue)</span>
          </Col>
        </Row>
      </Col>
      <Col className="stats" xs="5">
        <Row>
          <Col>
            <span className="number">{betteringStats.stats2.number}</span>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={9}>
            <span className="textBC">{betteringStats.stats2.text}</span>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className="justify-content-center rowText">
      <Col className="stats" xs="5">
        <Row>
          <Col>
            <span className="number">{betteringStats.stats3.number}</span>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={8}>
            <span className="textBC">{betteringStats.stats3.text}</span>
          </Col>
        </Row>
      </Col>
      <Col className="stats" xs="5">
        <Row>
          <Col>
            <span className="number">{betteringStats.stats4.number}</span>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <span className="textBC">{betteringStats.stats4.text}</span>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
);

export default BetteringChicago;
