export default [
  {
    image: "/assets/membersAndPartners/accenture_logo.png",
    title: "Accenture",
    desc: "A leading global professional services company providing a broad range of services in strategy and consulting, interactive, technology and operations, with digital capabilities and apprenticeships across all of these services.",
    learnMoreURL: "https://www.accenture.com/us-en/careers/jobdetails?id=R00048340_en&title=Entry+Level+Apprenticeship+-+NAELFY22",
    recruiting: "Ongoing",
    program_start: "Varies",
    program_length: "1 year"
  },
  {
    image: "/assets/membersAndPartners/aon_logo_eo.png",
    title: "AON",
    desc: "A leading global professional services firm providing a broad range of risk, reinsurance, retirement and health solutions. Offers a variety of apprenticeships across these areas as well as in technology and shared services.",
    learnMoreURL: "https://www.aon.com/careers/us/apprenticeships.html",
    recruiting: "November - March",
    program_start: "August",
    program_length: "2 years"
  },
  {
    image: "/assets/membersAndPartners/Catalyte_logo.svg",
    title: "Catalyte",
    desc: "A Baltimore-based software engineering consultancy with offices in Chicago and other cities. Finds talented people from unexpected places, trains them for free and hires them into technology apprenticeships to develop amazing software for internationally-known clients.",
    learnMoreURL: "https://bit.ly/CatChicago",
    recruiting: "Ongoing",
    program_start: "Every 5 weeks",
    program_length: "26 weeks"
  },
  {
    image: "/assets/membersAndPartners/zurich_logo.PNG",
    title: "Zurich North America",
    desc: "A global insurance provider to 95% of Fortune 500 businesses as well as midsize businesses and individuals. Recruiting for Associate Degree Apprenticeships and (new!) Bachelor's Degree Apprenticeships in cities coast to coast.",
    learnMoreURL: "https://www.zurichna.com/careers/apprentices",
    recruiting: "August - June",
    program_start: "August and January",
    program_length: "2 years"
  },
  {
    image: "/assets/membersAndPartners/journeycare_logo.png",
    title: "JourneyCare",
    desc: "The largest nonprofit provider of supportive palliative and hospice care in Illinois. JourneyCare apprenticeships prepare community health workers and nursing professionals for careers focused on community-based end-of-life care.",
    learnMoreURL: "mailto:vbate-ambrus@journeycare.org",
    recruiting: "August - September",
    program_start: "September - October",
    program_length: "2 years",
    hasContact: true,
    contactData: "Contact Dr. Venoncia M. Baté-Ambrus"
  },
  {
    image: "/assets/membersAndPartners/rush_logo(renamed).jpg",
    title: "Rush University System for Health (RUSH)",
    desc: "An academic health system whose mission is to improve the health of the individuals and diverse communities it serves through the integration of outstanding patient care, education, research and community partnerships. Rush offers a limited number of apprenticeships in nursing.",
    learnMoreURL: "mailto:Rush_Recruitment@rush.edu",
    recruiting: "March - July",
    program_start: "August - September",
    program_length: "1 year",
    hasContact: true,
    contactData: "Contact Rush Recruitment"
  },
  {
    image: "/assets/membersAndPartners/chase_logo.jpg",
    title: "JP Morgan Chase",
    desc: 'Consistently ranked as a top employer among Business and STEM majors, JPMorgan Chase & Co. is also one of Forbes\' "50 most Innovative Companies of 2020."',
    learnMoreURL: " https://careers.jpmorgan.com/us/en/students",
    recruiting: "November - April",
    program_start: "June",
    program_length: "1 year"
  },
  {
    image: "/assets/membersAndPartners/c4ita_ccc_logo.png",
    title: "Center for IT Talent Acceleration",
    desc: "C4ITA and Wilber Wright College of City Colleges of Chicago have partnered for SDA (Software Development Apprenticeship), a 2-year employer-sponsored “earn while you learn” apprenticeship program, designed to simultaneously perform paid part-time work while attending full-time college. Participants will learn 14-15 computer related courses during this period and will receive an Associate Degree from City Colleges of Chicago.",
    learnMoreURL: "https://www.c4ita.org/students",
    recruiting: "October - April",
    program_start: "June",
    program_length: "2 years"
  }
];
