import React from "react";
import { Card } from "react-bootstrap";
import "./style.css";

const ProgramCard = props => (
  <Card className="progcard-container">
    {props.isRecruiting && (
      <div className="progcard-recruiting-badge">RECRUITING</div>
    )}
    <Card.Img variant="top" src={props.image} alt={props.title===undefined? `Partner Logo`:`${props.title} Logo`}/>
    <Card.Body className="progcard-body">
      <Card.Text className="progcard-desc">{props.desc}</Card.Text>
      <Card.Text className="progcard-prog-info">
        {props.recruiting && (
          <>
            <span className="progcard-prog-info-heading">RECRUITING</span>
            &nbsp;&nbsp;&nbsp;<span>{props.recruiting}</span>
            <br />
          </>
        )}
        {props.program_start && (
          <>
            <span className="progcard-prog-info-heading">PROGRAM START</span>
            &nbsp;&nbsp;&nbsp;<span>{props.program_start}</span>
            <br />
          </>
        )}
        {props.program_length && (
          <>
            <span className="progcard-prog-info-heading">PROGRAM LENGTH</span>
            &nbsp;&nbsp;&nbsp;<span>{props.program_length}</span>
            <br />
          </>
        )}
        {props.recruiting_sources && (
          <>
            <span className="progcard-prog-info-heading">
              RECRUITING SOURCES
            </span>
            &nbsp;&nbsp;&nbsp;<span>{props.recruiting_sources}</span>
          </>
        )}
      </Card.Text>
    </Card.Body>
    <Card.Footer className="progcard-footer">
      {props.hasContact ? (
        <a href={props.learnMoreURL} target="_blank" rel="noopener noreferrer">
          {props.contactData}
        </a>
      ) : (
        <a href={props.learnMoreURL} target="_blank" rel="noopener noreferrer">
          Learn More
        </a>
      )}
    </Card.Footer>
  </Card>
);

export default ProgramCard;
