import React from "react";
import Header from "../shared/header/Header.jsx";
import cityDesktop from "../../assets/banner-desktop.jpg";
import cityMobile from "../../assets/banner-mobile.jpg";
import ExploreOpportunities from "./exploreOpportunities/ExploreOpportunities.jsx";
import ApprenticeshipPathways from "./apprenticeshipPathways/ApprenticeshipPathways.jsx";
import ExplorePrograms from "./explorePrograms/ExplorePrograms.jsx";
import WelcomeToNewCareer from "./welcomeToNewCareer/WelcomeToNewCareer.jsx";
import useWindowDimensions from "../shared/functions/headerBannerFormat.jsx";

let headerText = "Unlock your potential\nExplore apprenticeship";

const CareerSeekers = () => {
  const img = useWindowDimensions().width >= 768 ? cityDesktop : cityMobile;
  
  return (
   <div>
    <Header img={img} text={headerText} />
    <WelcomeToNewCareer />
    <ApprenticeshipPathways />
    <ExplorePrograms />
    <ExploreOpportunities />
   </div>
);}

export default CareerSeekers;
