import React from "react";
import { Card } from "react-bootstrap";
import jillBidenEvent from "../../../../assets/2022-11-14_Dr._Jill_Biden_on_stage_with_the_leaders.png";
import lightfootEvent from "../../../../assets/LightfootCANEvent07.19.jpg";
import "./style.css";
/**
 * @author
 * @function Box
 **/

const Box = props => {
  return (
    <div>
      <Card className="textWhite borderless">
      <Card.Img src={jillBidenEvent} alt="First Lady, Dr. Jill Biden, kicks off National Apprentice Week" />
      <Card.ImgOverlay className="d-flex flex-column justify-content-end lightfootSpeaking">
          <Card.Text className="red">
            First Lady, Dr. Jill Biden, kicks off National Apprentice Week
        </Card.Text>
      </Card.ImgOverlay>
      </Card>

      <br />

      <Card className="textWhite borderless">
      <Card.Img src={lightfootEvent} alt="Mayor Lori Lightfoot speaking at the 2019 Apprenticeship event" />
      <Card.ImgOverlay className="d-flex flex-column justify-content-end lightfootSpeaking">
        <Card.Text className="red">
          Mayor Lori Lightfoot speaking at an Apprenticeship event | JP
          Morgan Chase
        </Card.Text>
      </Card.ImgOverlay>
    </Card>
    </div>
  );
};

export default Box;