import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import PastEventCard from "../pastEvents/PastEventCard.jsx"
import inputPastEvents from "../../../inputs/inputPastEvents.js"

const PastEvents = () => {
  const eventList = inputPastEvents;

  const pastEventCardList = eventList.map((pastEvent, i) => (
    <Col key={i}>
      <PastEventCard pastEvent={pastEvent} />
    </Col> 
  ))

  return (
    <div>
      <h1 className="headingUnderline headingEventsPage wrapper">Past Events</h1>
      <Container className="eventHolder wrapper" fluid>
        <Row xs={1} md={2} lg={3}>{pastEventCardList}</Row>
      </Container>
    </div>
  )
}

export default PastEvents;
