export default [
  {
    name: "Danielle Polk",
    title: "Zurich Apprentice Alumna",
    quote:
      '"Before I started my Zurich apprenticeship, I was working two part-time jobs to be able to take care of myself. Now I can go to work and enjoy what I’m doing. I definitely see insurance as an exciting place to work."',

    image: "/assets/apprentices/daniellePolk.png",
    url: "",
  },
  {
    name: "Victor Gutierrez",
    title: "Aon Apprentice Alumnus",
    quote:      
      '"I’ve been given many responsibilities and I’m happy to know that I really am making an impact not only with my team but in Aon itself."',
    
    image: "/assets/apprentices/victorGutierrez.png",
    url: "https://www.youtube.com/watch?v=Dafll8lkRvU",
  },
  {
    name: "Loyda Mitacek",
    title: "Accenture Apprentice Alumna",
    quote:
      '"Cultural diversity and inclusion is good for everybody. Accenture gets diverse people, and the apprentices get to walk into a corporate culture that they otherwise wouldn’t have access to."',
    image: "/assets/apprentices/loydaMitacek.png",
    url: "",
  },
];
