export default [
  {
    name: "Jill Biden",
    title: "First Lady of the United States",
    quote:
      '"Create apprenticeships. Work with the high schools and community colleges in your areas. Mentor students, not because it will change lives, though it can, not because it will help your communities, though it will. But because when students here have the training that they need to succeed, your businesses will benefit. We can only realize the full power of our workforce when we tap into its full potential."',
    image: "/assets/recognition/FLOTUS.png"
  },
  {
    name: "Marty Walsh",
    title: "Secretary of Labor",
    quote:
      '"Apprenticeship are one of the most powerful workflow strategies that we have in our country right now. It was developed by workers and the unions and it has played a big role in building the middle class."',
    image: "/assets/recognition/Sec_of_Labor.png"
  },
  {
    name: "JB Pritzker",
    title: "Illinois Governor",
    quote:
      '"Apprenticeship programs are perfect opportunities to connect young talent with innovative companies that are ready to put them to work."',
    image: "/assets/recognition/JB_Pritzker.png",
    linkUrl:
      "https://www.facebook.com/GovPritzker/posts/apprenticeship-programs-are-perfect-opportunities-to-connect-young-talent-with-i/2889092927871715/",
  },
  {
    name: "Gina Raimondo",
    title: "Secretary of Commerce",
    quote:
      '"You have to change the way you hire. If you\'re going to just continue to say check the same old boxes, ‘We need a four-year college degree and five years of experience,’ this won\'t work. Right? It won\'t work."',
    image: "/assets/recognition/Sec_of_Commerce.png"
  },
  {
    name: "Miguel Cardona",
    title: "Secretary of Education",
    quote:
      '"You\'re showing the country the incredible power of apprenticeship and what it means for young people across this country to have new pathways to success, in everything from manufacturing and teaching, to banking and insurance."',
    image: "/assets/recognition/Sec_of_Ed.png"
  },
  {
    name: "Dick Durbin",
    title: "U.S. Senator from Illinois",
    quote:
      '"Creating opportunity is the bedrock of our country. … I applaud the employers of the Chicago Apprentice Network for building a bridge to talented young people"',
    image: "/assets/recognition/Dick_Durbin.png"
  },
  {
    name: "Lori Lightfoot",
    title: "Former Chicago Mayor",
    quote:
      '"This is how we create an economy that is ... dynamic, inclusive and equitable."',
    image: "/assets/recognition/Lori_Lightfoot.png",
    linkUrl:
      "https://www.chicagotribune.com/business/ct-biz-lightfoot-tech-apprenticeship-program-20190715-i2mc4fiyd5fqdj54yw7owarviu-story.html",
  },
  {
    name: "Emanuel “Chris” Welch",
    title: "Speaker of the Illinois House of Representatives",
    quote:
      '"The skilled trades have long used apprenticeships to grow their talent. I applaud the Chicago Apprentice Network for building upon this model."',
    image: "/assets/recognition/Emanuel_Welch.png"
  },
  {
    name: "Juan Salgado",
    title: "Chancellor of City Colleges of Chicago",
    quote:
      '"The apprenticeship network has opened doors for our students. At the same time, the companies have benefited from our students\' abundant talent — they aren’t missing out anymore."',
    image: "/assets/recognition/Juan_Salgado.png"
  },
  {
    name: "Thaddeus Jones",
    title: "Illinois State Representative",
    quote:
      '"No student should be prevented from being able to have a successful career because they could not afford the high cost of a degree."',
    image: "/assets/recognition/Thaddeus_Jones.png",
    linkUrl:
      "https://chicago.suntimes.com/2018/3/5/18368003/democratic-candidate-for-illinois-house-in-the-29th-district-thaddeus-jones",
  }
  // {
  //   name: "Chuck Weaver",
  //   title: "Illinois Sen.",
  //   quote:
  //     '"We need to provide additional opportunities for our students, help them recognize their talents and compete in the global workforce."',
  //   image: "/assets/recognition/placeholder.png"
  // },
];
